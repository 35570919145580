import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import artwork from "../../container/eventsStand/artwork";
import { getItem } from "../../utility/localStorageControl";

const Dashboard = lazy(() => import("../../container/dashboard"));

// const userDetails = getItem("userDetails");

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* {userDetails?.role == "admin" ? (
        <>
          {" "}
          <Route exact path={path} component={artwork} />
        </>
      ) : (
        <> */}
      {/* {" "} */}
      <Route exact path={path} component={Dashboard} />
      {/* </>
      )} */}
    </Switch>
  );
};

export default DashboardRoutes;
