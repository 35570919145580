import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Form, Input, Button, Modal, Row, Col } from "antd";
import { AuthWrapper } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { signUp, emailVari } from "../../../../redux/authentication/actionCreator";
import Heading from "../../../../components/heading/heading";

const SignUp = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [user, setUser] = useState("");

    const [state, setState] = useState({
        values: null,
        checked: null,
    });
    const [Visible, setVisible] = useState(false);
    const veriErr = useSelector((state) => state.auth.veriErr);
    // console.log("var error=", veriErr);

    const handleSubmit = async (formData) => {
        const payload = { ...formData, role: "admin" };
        const verification = await dispatch(signUp(payload));
        console.log("verification=", verification);
        if (verification) {
            if (verification.error) {
            } else {
                showModal();
                setUser(formData.email);
            }
        }
    };

    const onSubmit1 = async (value) => {
        const payload = { email: user, otp: value.otp };
        // console.log("payload=", payload);
        const verification = dispatch(emailVari(payload));
        // console.log("verification otp=", verification);
        if (!veriErr?.error) {
            // console.log("u r successfull");
            history.push("/");
        }
    };

    const showModal = () => {
        setVisible(true);
        form.resetFields();
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    return (
        <AuthWrapper>
            <div className="auth-contents">
                <Form name="register" onFinish={handleSubmit} layout="vertical">
                    <Heading as="h3">
                        Sign Up to <span className="color-secondary">Vendor</span>
                    </Heading>
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[{ required: true, message: "Please enter your First name!" }]}
                    >
                        <Input placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[{ required: true, message: "Please enter your Last name!" }]}
                    >
                        <Input placeholder="Last Name" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email Address"
                        rules={[
                            {
                                required: true,
                                message: "Please enter valid email!",
                                type: "email",
                            },
                        ]}
                    >
                        <Input placeholder="name@example.com" />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            { required: true, message: "Please add a password" },
                            { min: 8, message: "Password must have a minimum length of 8" },
                            {
                                pattern: new RegExp("^(?=.*[a-z])(?=.*\\d)(?=.*[@$!%*?&.])[A-Za-z\\d@$!%*?&]{8,}$"),
                                message:
                                    "Password must contain lowercase letter and at least one number and special character",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <div className="auth-form-action">
                        <p className="auth-notice">
                            Already have an account? <NavLink to="/">Sign In</NavLink>
                        </p>
                    </div>

                    <Form.Item>
                        <Button className="btn-create" htmlType="submit" type="primary" size="large">
                            Create Account
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Modal
                visible={Visible}
                title={"OTP Verification"}
                onOk={form.submit}
                onCancel={handleCancel}
                okText="Submit"
                width={500}
            >
                <Form name="nest-messages" layout="vertical" form={form} onFinish={onSubmit1}>
                    <Row gutter={25}>
                        <Col xxl={24} lg={24} md={24} xs={24}>
                            <Form.Item
                                name="otp"
                                label={
                                    <>
                                        OTP
                                        <span style={{ marginLeft: "3px" }}></span>{" "}
                                    </>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter otp send in your mail",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter OTP"
                                    type="tel"
                                    maxLength={6}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^\d]/g, "");
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </AuthWrapper>
    );
};

export default SignUp;
