import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AuthWrapper } from "./style";
import { login } from "../../../../redux/authentication/actionCreator";
import { Button } from "../../../../components/buttons/buttons";
import Heading from "../../../../components/heading/heading";

const SignIn = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.loading);
    const [form] = Form.useForm();

    const handleSubmit = (formData) => {
        const loginUser = dispatch(login(formData));
        if (loginUser) {
            console.log("loginUserData : ", loginUser);
        }
    };

    return (
        <>
            <AuthWrapper>
                {/* <p className="auth-notice">
        Don't have an account? <NavLink to="/register">Sign up now</NavLink>
      </p> */}
                <div className="auth-contents">
                    <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
                        <Heading as="h3">
                            Sign in to <span className="color-secondary">Account</span>
                        </Heading>

                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    message: "Please enter valid email!",
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder="Email Address" />
                        </Form.Item>
                        <Form.Item name="password">
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        <div className="auth-form-action">
                            <NavLink className="forgot-pass-link" to="/forgotPassword">
                                Forgot password?
                            </NavLink>
                            <p className="auth-notice">
                                New User? <NavLink to="/register">Sign Up</NavLink>
                            </p>
                        </div>
                        <Form.Item>
                            <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                                {isLoading ? "Loading..." : "Log In"}
                            </Button>
                        </Form.Item>

                        {/* <div className="auth-form-action">
              <NavLink className="forgot-pass-link" to="/forgotPassword">
                Forgot password?
              </NavLink>
            </div> */}
                    </Form>
                </div>
            </AuthWrapper>
        </>
    );
};

export default SignIn;
