// DEFINE ALL EXTERNAL IMPORT
import React, { useState } from "react";
import {
  Row,
  Col,
  Space,
  Table,
  Tag,
  Pagination,
  Modal,
  Form,
  Input,
  Select,
  message,
  Spin,
  Avatar,
} from "antd";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useStateRef from "react-usestateref";
// DEFINE ALL INTERNAL IMPORT
import { Button } from "../../components/buttons/buttons";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import FeatherIcon from "feather-icons-react";
import { Main } from "../styled";
import { API_SERVER_BASE_URL } from "../../config/dataService/dataService";
import {
  bundleList,
  bundleCreate,
  bundleDelete,
  bundleUpdate,
} from "../../redux/ar/actionCreator";

const limit = 10;
let docs = [];

// DEFINE MAIN FUNCTION
const BundleComponnet = () => {
  // DEFINE ALL STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [modalTitle, setModalTitle] = useState("Add Holidays");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocOpen, setIsDocOpen] = useState(false);
  const [iframeValue, setIframeValue, setIframeValueRef] = useStateRef("");
  const [previewFile, setPreviewFile] = useState("");
  const [iosUrl, setIosUrl] = useState("");
  const [previewFileData, setPreviewFileData] = useState("");
  const [iosUrlData, setIosUrlData] = useState("");
  const [previewMedia, setPreviewMedia] = useState("");
  const [previewMediaData, setPreviewMediaData] = useState("");
  const [assetBundle, setAssetBundle] = useState([]);
  const [subscriberPagination, setSubscriberPagination] = useState({});
  const [selectedId, setSelectedId, setSelectedIdRef] = useStateRef("");
  const [loader, setLoader] = useState(false);
  const [editData, setEditData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    getAssetBundleListAPI();
  }, [currentPage]);

  const getAssetBundleListAPI = async () => {
    let params = {
      page: currentPage,
      limit: limit,
    };
    setLoader(true);
    const subs = await dispatch(bundleList(params));
    if (subs) {
      setAssetBundle(subs?.assetData);
      setSubscriberPagination(subs?.meta);
    }
    setLoader(false);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (resp) => {
        if (resp.image_url == "") {
          return (
            // const [docTitle, setDocTitle] = useState("Document");
            <div>
              <Avatar
                icon={<FeatherIcon icon="user" size="30" />}
                style={{
                  backgroundColor: "#000000",
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </div>
          );
        } else {
          return (
            <>
              <img
                height={40}
                width={40}
                style={{ borderRadius: "50%" }}
                crossOrigin="anonymous"
                alt=""
                src={`${API_SERVER_BASE_URL + resp}`}
              />
            </>
          );
        }
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "Title",
      // render: (record) => {
      //   return (
      //     <>{record ? `${upperCaseFirstLetter(record?.title)}` : "Empty"}</>
      //   );
      // },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "Description",
      // render: (record) => {
      //   return (
      //     <>
      //       {record ? `${upperCaseFirstLetter(record?.description)}` : "Empty"}
      //     </>
      //   );
      // },
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Content Name",
      dataIndex: "content_name",
      key: "content_name",
    },

    {
      title: "Android ",
      dataIndex: "android_url",
      key: "android_url",
      align: "center",
      render: (resp) => {
        return (
          <>
            <Button
              style={{ marginRight: "15px" }}
              className="btn-icon"
              type="light"
              shape="circle"
              onClick={() => openDocVeiwer(resp)}
            >
              <FeatherIcon icon="eye" />
            </Button>
          </>
        );
      },
    },
    {
      title: "IOS",
      dataIndex: "ios_url",
      key: "ios_url",
      align: "center",
      render: (resp) => {
        return (
          <>
            <Button
              style={{ marginRight: "15px" }}
              className="btn-icon"
              type="light"
              shape="circle"
              onClick={() => openDocVeiwer(resp)}
            >
              <FeatherIcon icon="eye" />
            </Button>
          </>
        );
      },
    },

    {
      title: "Actions",
      key: "Actions",
      align: "center",
      render: (resp) => {
        return (
          <>
            <div className="table-actions">
              <>
                <Button
                  style={{ marginRight: "15px" }}
                  className="btn-icon"
                  type="light"
                  shape="circle"
                  onClick={() => showEditModal(resp)}
                >
                  <FeatherIcon icon="edit" size={16} />
                </Button>

                <Button
                  className="btn-icon"
                  type="light"
                  shape="circle"
                  onClick={() => deleteAssetBundle(resp._id)}
                >
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </>
            </div>
          </>
        );
      },
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
    setModalTitle("Add Bundle");
    form.resetFields();
    setSelectedId("");
    setPreviewMediaData("");
    setPreviewFileData("");
    setIosUrlData("");
    setPreviewFile("");
    setIosUrl("");
  };

  const openDocVeiwer = (value) => {
    docs = [
      {
        uri: `${API_SERVER_BASE_URL + value}`,
      },
    ];
    setIframeValue(value);
    setIsDocOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPreviewMedia("");
    setPreviewMediaData("");
    setPreviewFileData("");
    setIosUrlData("");
    setPreviewFile("");
    setIosUrl("");
  };

  const handledDocCancel = () => {
    setIsDocOpen(false);
  };

  const showEditModal = (value) => {
    setIsModalOpen(true);
    setModalTitle("Edit  Bundle");
    form.setFieldsValue({
      title: value?.title,
      description: value?.description,
      version: value?.version,
      content_name: value?.content_name,
      index: value?.index ? value?.index : 0,
    });

    if (value.image_url !== "") {
      setPreviewMediaData(value?.image_url);
      setPreviewMedia(
        <>
          <img
            height={50}
            width={50}
            src={API_SERVER_BASE_URL + value?.image_url}
            crossOrigin="anonymous"
          />
          <FeatherIcon icon="x" size="15" onClick={() => editHandler()} />
        </>
      );
    }

    if (value?.android_url !== "") {
      setPreviewFileData(value?.android_url);
      setPreviewFile(
        <>
          <div>{previewFileData}</div>
        </>
      );
    }
    if (value?.ios_url !== "") {
      setIosUrlData(value?.ios_url);
      setIosUrl(
        <>
          <div>{iosUrlData}</div>
        </>
      );
    }
    setSelectedId(value._id);
  };

  const handleSubmit = async (value) => {
    if (!value.description) {
      value.description = "";
    }
    var form_data = new FormData();
    delete value.android_url;
    delete value.image_url;
    delete value.ios_url;

    if (previewFileData !== "") {
      form_data.append("android_url", previewFileData);
    }
    if (previewMediaData !== "") {
      form_data.append("image_url", previewMediaData);
    }
    if (iosUrlData !== "") {
      form_data.append("ios_url", iosUrlData);
    }

    for (var key in value) {
      form_data.append(key, value[key]);
    }

    if (setSelectedIdRef.current == "") {
      setLoader(true);
      const create = await dispatch(bundleCreate(form_data));
      if (create) {
        getAssetBundleListAPI(currentPage, limit);
        setPreviewMediaData("");
        setPreviewMedia("");
        setPreviewFileData("");
        setIosUrlData("");
        setPreviewFile("");
        setIsModalOpen(false);
        setSelectedId("");
        form.resetFields();
      }
      setLoader(false);
    } else {
      setLoader(true);
      const update = await dispatch(
        bundleUpdate(setSelectedIdRef.current, form_data)
      );
      if (update) {
        getAssetBundleListAPI(currentPage, limit);
        setPreviewFileData("");
        setPreviewFile("");
        setIosUrl("");
        setPreviewMediaData("");
        setPreviewMedia("");
        setIsModalOpen(false);
        setSelectedId("");
        form.resetFields();
      }
      setLoader(false);
    }
    form.resetFields();
  };
  // };

  const deleteAssetBundle = async (value) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Asset Bundle?",
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
        setLoader(true);
        const deleteAsset = await dispatch(bundleDelete(value));
        if (deleteAsset) {
          setLoader(true);
          getAssetBundleListAPI(currentPage, limit);
          setLoader(false);
        }
        setLoader(false);
      },
    });
  };

  const handlePagination = async (page, pageSize) => {
    setCurrentPage(page);
  };

  const changeHandler = (e) => {
    setPreviewMediaData(e.target.files[0]);
    setPreviewMedia(
      <>
        <img
          height={100}
          width={100}
          src={URL.createObjectURL(e.target.files[0])}
        />
        <FeatherIcon icon="x" size="20" onClick={() => editHandler()} />
      </>
    );
  };
  const editHandler = () => {
    setPreviewMedia("");
    setPreviewMediaData("");
  };
  const editFileHandler = () => {
    setPreviewFile("");
    setPreviewFileData("");
  };
  const editFileHandlerForIos = () => {
    setIosUrl("");
    setIosUrlData("");
  };

  const changeHandlerFile = (e) => {
    setPreviewFileData(e.target.files[0]);
    setPreviewFile(
      <>
        <img
          height={100}
          width={100}
          src={URL.createObjectURL(e.target.files[0])}
        />
        <FeatherIcon icon="x" size="20" onClick={() => editHandler()} />
      </>
    );
  };
  const changeIosUrlData = (e) => {
    setIosUrlData(e.target.files[0]);
    setIosUrl(
      <>
        <img
          height={100}
          width={100}
          src={URL.createObjectURL(e.target.files[0])}
        />
        <FeatherIcon icon="x" size="20" onClick={() => editHandler()} />
      </>
    );
  };

  // DEFINE LOADER FUNCTION
  if (loader) {
    return (
      <Row justify={"center"} align={"middle"} style={{ height: "100vh" }}>
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  }

  //   DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <PageHeader
        ghost
        title="Bundle"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button size="small" type="primary" onClick={showModal}>
              <FeatherIcon icon="plus" size={14} />
              Add New
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards headless>
              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <AutoComplete
                    // onSearch={handleSearch}
                    // dataSource={notData}
                    placeholder="Search"
                    width="100%"
                    patterns
                  />
                </Col>
                <Col lg={24}>
                  <div className="table-order table-responsive">
                    {setAssetBundle && setAssetBundle.length > 0 ? (
                      <>
                        <Table
                          columns={columns}
                          dataSource={assetBundle}
                          pagination={false}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {subscriberPagination &&
                      subscriberPagination !== undefined ? (
                      <Pagination
                        total={subscriberPagination.total_counts}
                        current={currentPage}
                        pageSize={limit}
                        onChange={handlePagination}
                        simple={false}
                        hideOnSinglePage={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </Cards>
          </Col>
        </Row>
      </Main>

      <Modal
        open={isModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        closable={true}
        title={modalTitle}
        maskClosable={false}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 10 }}
          layout="vertical"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please Enter Title" }]}
          >
            <Input size="large" placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            label="Index"
            name="index"
            rules={[{ required: true, message: "Please Enter Index" }]}
          >
            <Input
              size="large"
              type="number"
              placeholder="Enter Index Number"
            />
          </Form.Item>
          <Form.Item
            label="Version"
            name="version"
            rules={[{ required: true, message: "Please Enter Version" }]}
          >
            <Input
              size="large"
              type="number"
              placeholder="Enter Version Number"
            />
          </Form.Item>
          <Row justify="start">
            <Col style={{ color: "red", marginRight: "5px" }}>*</Col>
            <Col>
              <Form.Item
                label="Android URL"
                name="android_url"
              // rules={[{ required: true, message: "Please Enter Content URL" }]}
              // initialValue={previewFileData}
              >
                <Input
                  size="large"
                  type="file"
                  placeholder="Android"
                  accept="*"
                  onChange={changeHandlerFile}
                />
                {previewFile ? (
                  <p>
                    <FeatherIcon icon="file-text" size="80" />
                    <FeatherIcon
                      style={{ marginBottom: "45px" }}
                      icon="x"
                      size="20"
                      onClick={() => editFileHandler()}
                    />
                  </p>
                ) : (
                  <span style={{ color: "red" }}>Please Enter Android URL</span>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start">
            <Col style={{ color: "red", marginRight: "5px" }}>*</Col>
            <Col>
              <Form.Item
                label="IOS URL"
                name="ios_url"
              // rules={[{ required: true, message: "Please Enter Content URL" }]}
              // initialValue={previewFileData}
              >
                <Input
                  size="large"
                  type="file"
                  placeholder="IOS URL"
                  accept="*"
                  onChange={changeIosUrlData}
                  name="ios_url"
                />
                {iosUrl ? (
                  <p>
                    <FeatherIcon icon="file-text" size="80" />
                    <FeatherIcon
                      style={{ marginBottom: "45px" }}
                      icon="x"
                      size="20"
                      onClick={() => editFileHandlerForIos()}
                    />
                  </p>
                ) : (
                  <span style={{ color: "red" }}>Please Enter IOS URL </span>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Content Name"
            name="content_name"
            rules={[{ required: true, message: "Please Enter Content Name" }]}
          >
            <Input size="large" placeholder="Enter Content Name" />
          </Form.Item>

          <Row justify="start">
            <Col style={{ color: "red", marginRight: "5px" }}>*</Col>
            <Col>
              <Form.Item
                label="Image"
                name="image_url"
              // rules={[{ required: true, message: "Please Enter Image URL" }]}
              // initialValue={previewMediaData}
              >
                <Input
                  size="large"
                  type="file"
                  placeholder="Image"
                  accept="image/*"
                  onChange={changeHandler}
                />

                {previewMedia ? (
                  previewMedia
                ) : (
                  <span style={{ color: "red" }}>Please Enter Image URL</span>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Description" name="description">
            <Input
              size="large"
              placeholder="Enter Content URL"
              defaultValue={""}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Doc Model */}
      <Modal
        open={isDocOpen}
        onCancel={handledDocCancel}
        closable={true}
        title="Document"
        footer={null}
      >
        <DocViewer
          documents={docs}
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
          }}
        // style={{ height: 500 }}
        />
        <p style={{ marginTop: "15px", marginBottom: "10px" }}>
          This browser does not support embedded documents.
        </p>
      </Modal>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default BundleComponnet;
