import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { Form, Input, Row, Col, Select, Modal, Tooltip, Popover } from "antd";
import {
  CardToolbox,
  Main,
  ListStyle,
  PricingCard,
  ListGroup,
  Badge,
} from "../styled";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import Heading from "../../components/heading/heading";
import { Button } from "../../components/buttons/buttons";
import { List } from "../../components/pricing/pricing";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Dropdown } from "../../components/dropdown/dropdown";
import { useDispatch } from "react-redux";
import {
  createPlan,
  getSubscriptionList,
  deletePlan,
  updatePlan,
} from "../../redux/ar/actionCreator";
import { currencies } from "../../utility/commonFunction";

const product = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Plan");
  const [convertedValue, setConvertedValue] = useState(0);
  const [currency, setCurrency] = useState("");
  const [arListData, setArListData] = useState([]);
  const [selectedId, setSelectedId, setSelectedIdRef] = useStateRef("");
  const [editPayload, setEditPayload] = useState({ price: "", prod: "" });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const list = await dispatch(getSubscriptionList());
    if (list) {
      setArListData(list);
    }
  };

  const sortedCurrencies = currencies.sort(function (a, b) {
    if (a.code < b.code) {
      return -1;
    }
    if (a.code > b.code) {
      return 1;
    }
    return 0;
  });

  const showModal = () => {
    setIsModalOpen(true);
    setFile("");
    form.resetFields();
  };

  const showEditModal = (value) => {
    setEditPayload({ price: value.price_id, prod: value.product_id });
    setConvertedValue(value.amount);
    setCurrency(value.currency);
    setSelectedId(value._id);
    setIsModalOpen(true);
    form.setFieldsValue({
      name: value.type,
      interval: value.interval,
      interval_count: value.interval_count,
      SUBSCRIPTION_TYPE: value.SUBSCRIPTION_TYPE,
      unit_amount: value.amount,
      currency: value.currency,
      description: value.feature,
      product_id: value.product_id,
      price_id: value.price_id,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedId("");
    form.resetFields();
  };

  const handleSubmit = async (value) => {
    value.unit_amount = convertedValue;
    value.currency = currency;
    if (setSelectedIdRef.current == "") {
      const create = await dispatch(createPlan(value));
      if (create) {
        getList();
        setIsModalOpen(false);
        setCurrency("");
        setConvertedValue(0);
        form.resetFields();
        setSelectedId("");
      }
    } else {
      value.price_id = editPayload.price;
      value.product_id = editPayload.prod;
      const update = await dispatch(updatePlan(value));
      if (update) {
        getList();
        setIsModalOpen(false);
        setCurrency("");
        setConvertedValue(0);
        form.resetFields();
        setSelectedId("");
      }
    }
  };
  const deleteFunction = (id) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Plan?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        const plandelete = dispatch(deletePlan(id));
        if (plandelete) {
          getList();
        }
      },
    });
  };

  const handleBaseCurrencyChange = (v) => {
    setCurrency(v);
    currencies.map((rt) => {
      if (rt.code == v) {
        setConvertedValue(0);
        let price = convertedValue * rt.value;
        setConvertedValue(price);
      }
    });
  };
  const getSymbol = () => {
    let cur = currency.toUpperCase();
    return getSymbolFromCurrency(cur);
  };

  return (
    <>
      <PageHeader
        title="Plans"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button
              size="small"
              type="primary"
              onClick={() => setIsModalOpen(true)}
            >
              <FeatherIcon icon="plus" size={14} />
              Add Plan
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Row gutter={[26, 26]} justify="start">
          {arListData &&
            arListData.length > 0 &&
            arListData.map((val) => {
              return (
                <>
                  <Col xxl={6} lg={8} sm={12} xs={24}>
                    <PricingCard>
                      <Badge className="pricing-badge" type="primary">
                        {val.type}
                      </Badge>

                      <Tooltip placement="Bottom" className="edit-tooltip">
                        <FeatherIcon
                          icon="trash"
                          size="20"
                          className="feather_icon"
                          onClick={() => deleteFunction(val.product_id)}
                        />
                      </Tooltip>
                      <Heading className="price-amount" as="h3">
                        <sup className="currency">
                          {val.currency ? getSymbol(val.currency) : "$"}
                        </sup>
                        {val.amount}
                        <sub className="pricing-validity">Per month</sub>
                      </Heading>
                      <span className="package-user-type">For 2 Users</span>
                      <ListGroup>
                        <List text={`Features - ${val.feature}`} />
                        <List text={`Interval Count - ${val.interval_count}`} />
                        <List text={`Interval - ${val.interval}`} />
                      </ListGroup>
                    </PricingCard>
                  </Col>
                </>
              );
            })}
        </Row>
      </Main>

      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        okText={"submit"}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 5 }}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please Enter Name" }]}
          >
            <Input placeholder="Enter name" type="text" />
          </Form.Item>

          <Row gutter={[10, 10]}>
            <Col lg={19}>
              <Form.Item
                label="Price"
                name="unit_amount"
                rules={[{ required: true, message: "Please Enter Price" }]}
              >
                <Input
                  type="number"
                  onChange={(e) => setConvertedValue(e.target.value)}
                  placeholder="Enter price"
                  width="70"
                />
              </Form.Item>
            </Col>
            <Col lg={5}>
              <Form.Item
                label={getSymbolFromCurrency("INR")}
                name="currency"
                rules={[{ required: true, message: "Please Select Currency" }]}
              >
                <Select
                  onChange={handleBaseCurrencyChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100px" }}
                >
                  {sortedCurrencies.map((option) => (
                    <Select.Option key={option.code} value={option.code}>
                      {option.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <span>Converted price from rs to {currency} - </span>
            {parseFloat(convertedValue).toFixed(2)}
          </Row>

          <Form.Item
            label="Interval"
            name="interval"
            rules={[{ required: true, message: "Please Select Interval" }]}
          >
            <Select style={{ width: "100%" }} placeholder="Select Interval">
              <Select.Option key="1" value="year">
                year
              </Select.Option>
              <Select.Option key="2" value="month">
                month
              </Select.Option>
              <Select.Option key="3" value="week">
                week
              </Select.Option>
              <Select.Option key="4" value="day">
                week
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Interval Count"
            name="interval_count"
            rules={[{ required: true, message: "Please Enter Interval Count" }]}
          >
            <Input type="number" placeholder="Enter interval count" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please Enter Description" }]}
          >
            <Input type="textarea" placeholder="Enter description" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default product;
