import Cookies from "js-cookie";
import actions from "./actions";
import { message } from "antd";
import { removeItem, setItem } from "../../utility/localStorageControl";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, verifyErr } = actions;

const login = (payloads) => {
    return async (dispatch) => {
        try {
            const loggedIn = await DataService.post(API.auth.login, payloads);
            // console.log("login=", loggedIn.data.data);
            if (!loggedIn.data.error) {
                setItem("access_token", loggedIn.data.data.token);
                setItem("userDetails", loggedIn.data.data);
                setItem("subscription_data", loggedIn.data.data?.subscription?.type);
                Cookies.set("logedIn", true);
                dispatch(loginSuccess(true));

                window.location.reload();
                return loggedIn.data.data;
            } else {
                dispatch(loginErr(loggedIn));
                message.error("invalid Details");
            }
        } catch (err) {
            dispatch(loginErr(err));
        }
    };
};

const signUp = (payloads) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.auth.signUp, payloads);
        if (!resp.data.error) {
            return resp;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

const emailVari = (payloads) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.auth.verify, payloads);
        // console.log("redux response=", resp, resp.error);
        if (!resp.error) {
            // console.log("true resp");
            return resp;
        } else {
            // console.log("false resp", resp);
            dispatch(verifyErr(resp));

            // message.error(resp.data.message);
            return resp;
        }
    };
};

const changePassword = (payloads) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.auth.changePassword, payloads);
        if (!resp.data.error) {
            return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

const resetPassword = (payloads) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.auth.resetPass, payloads);
        if (!resp.data.error) {
            // return resp.data.data;
            return true;
        } else {
            message.error(resp.data.message);
            return false;
        }
    };
};

const logOut = () => {
    return async (dispatch) => {
        try {
            dispatch(logoutBegin());
            removeItem("access_token");
            removeItem("userDetails");
            removeItem("totalTime");
            removeItem("remainingTime");
            Cookies.remove("logedIn");
            dispatch(logoutSuccess(null));
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};

export { login, logOut, changePassword, signUp, emailVari, resetPassword };
