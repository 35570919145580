import React from "react";
import io from "socket.io-client";
import { getItem } from "../utility/localStorageControl";
const socketUrl = require("../../src/config/dataService/dataService").SOCKET_ENDPOINT;

const SocketContext = React.createContext();

let userDetails = getItem("userDetails");

const SocketProvider = ({ children }) => {
    const socket = io(socketUrl, {
        extraHeaders: {
            userid: userDetails && userDetails != "" ? userDetails._id : "",
        },
    });
    console.log("socket 16: ", socket);
    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export { SocketContext, SocketProvider };
