import actions from "./actions";
import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";

const { getartWorkList } = actions;

export const artworkGet = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      queryString += value + "=" + params[value] + "&";
    });

    const resp = await DataService.get(API.artlist.list + queryString);
    if (!resp.data.error) {
      dispatch(getartWorkList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const artworkGetData = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.artlist.getList + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const artworkCreate = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.artlist.create, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const artworkupdate = (id, payload) => {
  console.log("payyylloooaddd");
  return async (dispatch) => {
    const resp = await DataService.put(API.artlist.update + "/" + id, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const artWorkDelete = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.artlist.delete + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
