import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { getItem } from "../utility/localStorageControl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const userDetails = getItem("userDetails");

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const [selectedKeys, setSelectedKeys] = useState(
    !topMenu
      ? [`${mainPathSplit.length == 1 ? "dashboard" : mainPathSplit[1]}`]
      : []
  );

  useEffect(() => {
    if (mainPath == "") {
      setSelectedKeys("dashboard");
    } else if (mainPath == "/artwork") {
      setSelectedKeys("artwork");
    } else if (mainPath == "/subscription") {
      setSelectedKeys("subscription");
    } else if (mainPath == "/users") {
      setSelectedKeys("users");
    } else if (mainPath == "/product") {
      setSelectedKeys("product");
    } else if (mainPath == "/subscriber") {
      setSelectedKeys("subscriber");
    }
  }, [location]);
  const onOpenChange = (keys) => {
    setOpenKeys([keys.length && keys[keys.length - 1]]);
  };
  const onClick = (item) => {
    setSelectedKeys(item.key);
  };
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode && "dark"}
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1
              ? "dashboard"
              : mainPathSplit.length === 2
                ? mainPathSplit[1]
                : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      selectedKeys={selectedKeys}
    >
      {userDetails?.role == "super_admin" && (
        <>
          <Menu.Item
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="activity"
                  onClick={() => {
                    history.push({
                      pathname: `${path}`,
                    });
                  }}
                />
              )
            }
            key="dashboard"
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}`,
                pageName: "dashboard",
              }}
            >
              Dashboard
            </NavLink>
          </Menu.Item>

          <Menu.Item
            className="active"
            key="users"
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="users"
                  onClick={() => {
                    history.push({
                      pathname: `${path}/users`,
                    });
                  }}
                />
              )
            }
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/users`,
                pageName: "users",
              }}
            >
              Users
            </NavLink>
          </Menu.Item>

          {/* <Menu.Item
            className="active"
            key="asset"
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="plus"
                  onClick={() => {
                    history.push({
                      pathname: `${path}/asset`,
                    });
                  }}
                />
              )
            }
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/asset`,
                pageName: "asset",
              }}
            >
              asset
            </NavLink>
          </Menu.Item> */}
          <Menu.Item
            className="active"
            key="bundle"
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="plus"
                  onClick={() => {
                    history.push({
                      pathname: `${path}/bundle`,
                    });
                  }}
                />
              )
            }
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/bundle`,
                pageName: "bundle",
              }}
            >
              Bundle
            </NavLink>
          </Menu.Item>

          <Menu.Item
            className="active"
            key="subscriber"
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="dollar-sign"
                  onClick={() => {
                    history.push({
                      pathname: `${path}/subscriber`,
                    });
                  }}
                />
              )
            }
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/subscriber`,
                pageName: "subscriber",
              }}
            >
              Subscriber
            </NavLink>
          </Menu.Item>
          {/* 
          <Menu.Item
            className="active"
            key="product"
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="plus"
                  onClick={() => {
                    history.push({
                      pathname: `${path}/product`,
                    });
                  }}
                />
              )
            }
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/product`,
                pageName: "product",
              }}
            >
              Product
            </NavLink>
          </Menu.Item> */}

          {/* <Menu.Item
            className="active"
            key="product"
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="plus"
                  onClick={() => {
                    history.push({
                      pathname: `${path}/product`,
                    });
                  }}
                />
              )
            }
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/product`,
                pageName: "product",
              }}
            >
              Product
            </NavLink>
          </Menu.Item> */}
        </>
      )}

      {userDetails?.role == "admin" && (
        <>
          <Menu.Item
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="activity"
                  onClick={() => {
                    history.push({
                      pathname: `${path}`,
                    });
                  }}
                />
              )
            }
            key="dashboard"
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}`,
                pageName: "dashboard",
              }}
            >
              Dashboard
            </NavLink>
          </Menu.Item>
          <Menu.Item
            className="active"
            key="artwork"
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="codepen"
                  onClick={() => {
                    history.push({
                      pathname: `${path}/artwork`,
                    });
                  }}
                />
              )
            }
          >
            <NavLink
              key="artwork"
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/artwork`,
                pageName: "artwork",
              }}
            >
              AR Marker List
            </NavLink>
          </Menu.Item>
          <Menu.Item
            className="active"
            key="subscription"
            icon={
              !topMenu && (
                <FeatherIcon
                  icon="dollar-sign"
                  onClick={() => {
                    history.push({
                      pathname: `${path}/subscription`,
                    });
                  }}
                />
              )
            }
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/subscription`,
                pageName: "subscription",
              }}
            >
              Subscription
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item
            className="active"
            key="practice"
            icon={!topMenu && <FeatherIcon icon="dollar-sign" />}
          >
            <NavLink
              onClick={toggleCollapsed}
              to={{
                pathname: `${path}/practice`,
                pageName: "practice",
              }}
            >
              practice
            </NavLink>
          </Menu.Item> */}
        </>
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
