import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader/root";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import store from "./redux/store";
import Admin from "./routes/admin";
import Auth from "./routes/auth";
import "./static/css/style.css";
import "./static/css/media-query.css";
import "./static/css/custome.css";
import config from "./config/config";
import ProtectedRoute from "./components/utilities/protectedRoute";
import SignUp from "./container/profile/authentication/overview/Signup";
import SignIn from "./container/profile/authentication/overview/SignIn";
import { SocketProvider } from "./socekt/socketContext";
import { getItem } from "./utility/localStorageControl";

const { theme } = config;
const userDetails = getItem("userDetails");

const ProviderConfig = () => {
    const { rtl, isLoggedIn, topMenu, darkMode } = useSelector((state) => {
        return {
            darkMode: state.ChangeLayoutMode.data,
            rtl: state.ChangeLayoutMode.rtlData,
            topMenu: state.ChangeLayoutMode.topMenu,
            isLoggedIn: state.auth.login,
        };
    });

    const [path, setPath] = useState(window.location.pathname);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setPath(window.location.pathname);
        }
        // eslint-disable-next-line no-return-assign
        return () => (unmounted = true);
    }, [setPath]);

    return (
        <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
            <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
                <Router basename={process.env.PUBLIC_URL}>
                    {!isLoggedIn ? (
                        // <Route exact path="/" component={SignIn} />||
                        // <Route exact path="/register" component={SignUp} />

                        <Route path="/" component={Auth} />
                    ) : (
                        <ProtectedRoute path="/admin" component={Admin} />
                    )}
                    {isLoggedIn &&
                        (path === process.env.PUBLIC_URL ||
                            path === `${process.env.PUBLIC_URL}/` ||
                            path === `${process.env.PUBLIC_URL}/login`) && <Redirect to="/admin" />}
                </Router>
            </ThemeProvider>
        </ConfigProvider>
    );
};

function App() {
    if (userDetails && userDetails != "") {
        return (
            <Provider store={store}>
                <SocketProvider>
                    <ProviderConfig />
                </SocketProvider>
            </Provider>
        );
    } else {
        return (
            <Provider store={store}>
                <ProviderConfig />
            </Provider>
        );
    }
}

export default hot(App);
