const actions = {
  //DEFINE RETURN DATA SET
  GET_EVENT: "GET_EVENT",
  GET_USER: "GET_USER",
  RESET: 'RESET',

  getEvent: (data) => {
    return {
      type: actions.GET_EVENT,
      data,
    };
  },
  getUser: (data) => {
    return {
      type: actions.GET_USER,
      data,
    }
  },
  reset: data => {
    return {
      type: actions.RESET,
      data,
    }
  },
};

export default actions;
