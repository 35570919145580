import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  PageHeader,
  Input,
  Row,
  Col,
  Switch,
  Popconfirm,
  Modal,
  Form,
  Image,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Tooltip,
  Tag,
} from "antd";
import FeatherIcon from "feather-icons-react";
import useStateRef from "react-usestateref";
// import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getArtwork,
  getUserList,
  updateBlock,
  resetFunction
} from "../../redux/ar/actionCreator";
import { Cards } from "../../components/cards/frame/cards-frame";
import { CardToolbox, Main } from "../styled";

import moment from "moment";

import { Link, useParams } from "react-router-dom";
import { render } from "less";

const Users = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [currentPage, setCurrentPage] = useState(1)
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [role, setRole] = useState('')
  const { Search } = Input;
  const dispatch = useDispatch();

  const params = useParams();
  //USE EFFECT FUNCTION CALL
  useEffect(() => {
    getList(1, 10, role);
    return () => {
      dispatch(resetFunction())
    }
  }, []);

  //EVENT MASTER TABLE LIST API CALL
  const getList = async (page, pageSize, role) => {
    await dispatch(getUserList(page, pageSize, role));


  };
  const userListSelector = useSelector((state) => state.user.userList.data);
  const userListPagination = useSelector((state) => state.user.userList.meta);

  const handleFilter = async (value) => {
    setCurrentPage(1)
    setRole(value)
    getList(1, defaultPageSize, value)
  };

  const onPageChange = async (page, pageSizes) => {
    setCurrentPage(page)
    getList(page, pageSizes, role);
    // await dispatch(getEventData(searchValueRef.current, page, pageSizes));
  };

  const onShowSizeChange = async (current, pageSizes) => {
    setDefaultPageSize(pageSizes);
  };

  //EVENT MASTER TABLE LIST REDUX RESPONSE

  let eventHallList = useSelector((state) => state.eventHall.eventHallList);
  console.log('userListSelector :', userListPagination)

  let eventHallListData =
    eventHallList && eventHallList.data && eventHallList.data
      ? eventHallList.data
      : "";

  const deleteEventFunction = async (id) => {
    const deleted = await dispatch(deleteEvent(id));
    if (deleted) {
      getList(params.eventId);
    }
  };
  // const rowClassName = (record) =>{
  //   console.log('record : ', record.role)
  //   if (record.role == "super_admin") {
  //     return "hidden-row"
  //   } else {
  //     return ''
  //   } 
  // }
 

  //TABLE COLUMNS DEFINE
  const columns = useMemo(() => [
    // {
    //   title:'id',
    //   dataIndex:'_id',
    //   key:'ID'
    // },
    {
      title: "Name",
      dataIndex: 'firstName',
      key: 'Name',
      render: (data, record) => {
        return (
          <span>
            {record && record.firstName
              ? record.firstName + "  " + record?.lastName
              : "-"}
          </span>
        )
      }
      // render(data, record) {
      //   return {
      //     children: (
      // <span>
      //   {data && data.firstName
      //     ? data.firstName + "  " + data?.lastName
      //     : "-"}
      // </span>
      //     ),
      //   };
      // },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "Email"
      // render(data, record) {
      //   return {
      //     children: <span>{data && data.email ? data.email : "-"}</span>,
      //   };
      // },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "Role",
      // dataIndex:"role",
      // filters: [
      //   { text: "admin", value: "admin" },
      //   { text: "user", value: "user" },
      // ],
      // onFilter: (value, record) => record.role === value,
      // render(data, record) {
      //   return {
      //     children: <span>{data && data.role ? data.role : "-"}</span>,
      //   };
      // },
    },
    {
      title: "Subscription",
      dataIndex: 'subscription',
      key: "Subscription",
      render: (data, record) => {
        // console.log('subscription : ', record.subscription)
        return (
          <>
            <span>
              {record && record.subscription ? (
                <Tag color="magenta" style={{ color: "#c41d7f" }}>
                  {" "}
                  {record.subscription.type}
                </Tag>
              ) : (
                "-"
              )}
            </span>
          </>
        )
      }
      // render: (data, record) => {
      //   return {
      //     // children: (
      // <span>
      //   {data && data.subscription ? (
      //     <Tag color="magenta" style={{ color: "#c41d7f" }}>
      //       {" "}
      //       {data.subscription.type}
      //     </Tag>
      //   ) : (
      //     "-"
      //   )}
      // </span>
      //     // ),
      //   };
      // },
    },

    {
      title: "Action",
      dataIndex: "status",
      key: "Action",

      render: (data, record) => {
        return (
          <>
            <div className="table-actions">
              {record.role != "super_admin" && (
                <Switch
                  defaultChecked={record.status == "active"}
                  onChange={async (checked) => {
                    const payload = { userId: record._id };
                    const x = await dispatch(updateBlock(payload));
                  }}
                />
              )}
            </div>

          </>
        )

      }
    },
  ]
    ,
    [])


  //REANGE PICKER IN CURRENT DATE DEFORE DATE DISABLE FUNCTION DEFINE
  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  return (
    <>
      {/* DEFINE PAGE HEADER */}

      {/* <CardToolbox>
        <Row justify='start' gutter={[24, 24]}>
          <Col>
            <PageHeader
              ghost
              title={`User Master`}
              extra={
                [
                  // <div key={1} className="page-header-actions">
                  //     <NavLink
                  //         to={`${path.slice(0, -6)}arstand`}
                  //         // onClick={() => {
                  //         //     console.log("hii arlist=", path)
                  //         //     history.push(`/`)}}
                  //         className="ant-btn ant-btn-primary ant-btn-md"
                  //     >
                  //         <FeatherIcon
                  //             icon="plus"
                  //             size={14}
                  //             className="custom-align-plus-icon"
                  //         />
                  //         Add New
                  //     </NavLink>
                  // </div>,
                ]
              }
            />
          </Col>
          <Col>
            <p>hi</p>
          </Col>
        </Row >
      </CardToolbox> */}
      <Row justify='start' align='middle'>
        <Col>
          <PageHeader
            ghost
            title={`User Master`}
          />
        </Col>
        <Col>
          <Select
            className="filter"
            style={{ width: "200px", }}
            placeholder="Filter By Role"
            onChange={handleFilter}
            options={[
              {
                value: "admin",
                label: "Admin",
              },
              {
                value: "user",
                label: "User",
              },
              {
                value: "",
                label: "Both",
              }

            ]}
          />
        </Col>
      </Row>




      {/* TABLE DEFINE */}
      < Main >
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards headless>
              <div className="table-order table-responsive">
                {
                  userListSelector && userListSelector.length && userListPagination && userListPagination !== undefined > 0 ?
                    <Table
                      dataSource={userListSelector}
                      // rowClassName={rowClassName}
                      columns={columns}
                      rowKey="_id"

                      pagination={{
                        showSizeChanger: false,
                        pageSize: defaultPageSize,
                        total: userListPagination?.totalCounts,
                        current: currentPage,
                        // onShowSizeChange: onShowSizeChange,
                        onChange: onPageChange,
                      }}
                    /> : ''
                }

              </div>
            </Cards>
          </Col>
        </Row>
      </Main >
    </>
  );
};

export default Users;
