import React, { Suspense, useState } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";
import withAdminLayout from "../../layout/withAdminLayout";
import users from "../../container/users/users";
import Artwork from "../../container/eventsStand/artwork";
import Subscription from "../../container/subscription/subscription";
import editeArtwork from "../../container/eventsStand/editeArtwork";
import product from "../../container/product/product";
import Success from "../../container/pages/succes";
import Cancel from "../../container/pages/cancel";
import practice from "../../container/practice/practice";
import subscriber from "../../container/subscribers/subscriber";
import { SocketProvider } from "../../socekt/socketContext";
import assetBundle from "../../container/assetBundle/assetBundle";
import BundleComponnet from "../../container/BundleComponets/BundleComponnets";

const Admin = () => {
  const { path } = useRouteMatch();

  const key = window.location.href.split("=").pop();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route
          exact
          path={path}
          title={"TestingDashboard"}
          component={Dashboard}
        />
        <Route exact path={`${path}/users`} component={users} />
        <Route exact path={`${path}/subscription`} component={Subscription} />
        <Route exact path={`${path}/artwork`}>
          <Artwork />
        </Route>
        <Route exact path={`${path}/product`} component={product} />
        {/* <Route exact path={`${path}/practice`} component={Practice} /> */}
        <Route
          exact
          path={`${path}/editeartwork/:id`}
          component={editeArtwork}
        />
        <Route exact path={`${path}/subscriber`} component={subscriber} />
        <Route exact path={`${path}/asset`} component={assetBundle} />
        <Route exact path={`${path}/bundle`} component={BundleComponnet} />
        {key == window.location.href ? (
          <Route
            exact
            path={`${path}/subscription/success?session_id=${key}`}
            component={Success}
          />
        ) : (
          <Route
            exact
            path={`${path}/subscription/success`}
            component={Success}
          />
        )}
        <Route path={`${path}/subscription/cancel`} component={Cancel} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
