import actions from "./actions";

const { GET_EVENT_HALLS } = actions;

const initState = {
  loading: false,
  eventHallList: {},
};

const eventHallReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_EVENT_HALLS:
      return {
        ...state,
        loading: true,
        eventHallList: data,
      };
    default:
      return state;
  }
};
export default eventHallReducer;
