import { combineReducers } from "redux";
import authReducer from "./authentication/reducers";
import ChangeLayoutMode from "./themeLayout/reducers";
import dashboardReducer from "./dashboard/reducers";
import userReducer from "./users/reducers";
// import eventReducer from "./events/reducers";
import eventHallReducer from "./eventHalls/reducers";
// import staticPageReducer from "./staticPageContent/reducers";
// import DemoReducer from "./demo/reducers";
import artWorkReducerer from "./arList/reducers";

const rootReducers = combineReducers({
  auth: authReducer,
  ChangeLayoutMode,
  user: userReducer,
  dashboard: dashboardReducer,
  // event: eventReducer,
  eventHall: eventHallReducer,
  // staticContent: staticPageReducer,
  // demo: DemoReducer,
  artWork: artWorkReducerer,
});

export default rootReducers;
