const actions = {
  //DEFINE RETURN DATA SET
  GET_EVENT_HALLS: "GET_EVENT_HALLS",

  getEventHalls: (data) => {
    return {
      type: actions.GET_EVENT_HALLS,
      data,
    };
  },
};

export default actions;
