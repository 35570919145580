import actions from "./actions";

const { GET_DASHBOARD_COUNTS, GET_DASHBOARD_COUNTS_DOUGHNUT_CHART } = actions;

const initState = {
  loading: false,
  dashboardData: {},
  dashboardDataDoughnutChart: {},
};

const dashboardReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_DASHBOARD_COUNTS:
      return {
        ...state,
        dashboardData: data,
      };
    case GET_DASHBOARD_COUNTS_DOUGHNUT_CHART:
      return {
        ...state,
        dashboardDataDoughnutChart: data,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
