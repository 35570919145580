const actions = {
  //DEFINE RETURN DATA SET
  GET_DASHBOARD_COUNTS: "GET_DASHBOARD_COUNTS",
  GET_DASHBOARD_COUNTS_DOUGHNUT_CHART: "GET_DASHBOARD_COUNTS_DOUGHNUT_CHART",

  getDashboardBarChartCounts: (data) => {
    return {
      type: actions.GET_DASHBOARD_COUNTS,
      data,
    };
  },
  getDashboardDoughnutChartCounts: (data) => {
    return {
      type: actions.GET_DASHBOARD_COUNTS_DOUGHNUT_CHART,
      data,
    };
  },
};

export default actions;
