import React from "react";
import { Avatar, Tag, Button, Popconfirm } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { InfoWraper, UserDropDwon } from "./auth-info-style";
import { Popover } from "../../popup/popup";
import { logOut } from "../../../redux/authentication/actionCreator";
import Heading from "../../heading/heading";
import { getItem } from "../../../utility/localStorageControl";
import { get } from "js-cookie";




const userDetails = getItem("userDetails")
const subscription = userDetails && userDetails?.isSubscribed

const AuthInfo = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const subscription = getItem("userDetails");
  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };

  const buySubscription = () => {
    history.push("/admin/subscription");
  };
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        {/* <figure className="user-dropdwon__info">
          <img
            src={require("../../../static/img/avatar/chat-auth.png")}
            alt=""
          />
          <figcaption>
            <Heading as="h5">{getItem("userDetails").firstName}</Heading>
          </figcaption>
        </figure> */}
        {/* <Popconfirm
          title="Please confirm"
          description="Do you want to logout"
          onConfirm={SignOut}
          // onOpenChange={() => console.log("open change")}
        > */}
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
        {/* </Popconfirm> */}
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper style={{ padding: "0" }}>
      {subscription?.role == "super_admin" ? (
        <></>
      ) : subscription?.isSubscribed ? (
        <>
          {" "}
          <Tag color="magenta">subscribed</Tag>{" "}
        </>
      ) : (
        <>
          {" "}
          <Button type="primary" onClick={() => buySubscription()}>
            Buy Subscription
          </Button>
        </>
      )}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <div className="right_profile">
            {/* <p> {getItem("userDetails").firstName}</p> */}
            <Link to="#" className="head-example">
              {/* <Avatar
                size={40}
                src={require("../../../static/img/avatar/profile-admin.png")}
                alt=""
              /> */}
              <Avatar
                src={require("../../../static/img/avatar/profile-admin.png")}
                size={40}
              />
            </Link>
          </div>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
