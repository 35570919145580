const actions = {
  //DEFINE RETURN DATA SET
  artWorkList: "artWorkList",

  getartWorkList: (data) => {
    return {
      type: actions.artWorkList,
      data,
    };
  },
};

export default actions;
