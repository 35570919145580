import React, { useState } from "react";
import useStateRef from "react-usestateref";
import { Button } from "../../components/buttons/buttons";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import FeatherIcon from "feather-icons-react";
import { Main } from "../styled";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { API_SERVER_BASE_URL } from "../../config/dataService/dataService";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { upperCaseFirstLetter } from "../../utility/commonFunction";
import {
  Row,
  Col,
  Space,
  Table,
  Tag,
  Pagination,
  Modal,
  Form,
  Input,
  Select,
  message,
} from "antd";
import {
  assetBundleList,
  assetBundleCreate,
  assetBundleDelete,
  assetBundleUpdate,
} from "../../redux/ar/actionCreator";

const limit = 10;
let docs = [];

const assetBundle = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [modalTitle, setModalTitle] = useState("Add Holidays");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocOpen, setIsDocOpen] = useState(false);
  const [iframeValue, setIframeValue, setIframeValueRef] = useStateRef("");

  const [previewFile, setPreviewFile] = useState("");
  const [previewFileData, setPreviewFileData] = useState("");

  const [previewMedia, setPreviewMedia] = useState("");
  const [previewMediaData, setPreviewMediaData] = useState("");

  const [assetBundle, setAssetBundle] = useState([]);
  const [subscriberPagination, setSubscriberPagination] = useState({});
  const [selectedId, setSelectedId, setSelectedIdRef] = useStateRef("");

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    getAssetBundleListAPI();
  }, []);

  const getAssetBundleListAPI = async () => {
    let params = {
      page: currentPage,
      limit: limit,
    };

    const subs = await dispatch(assetBundleList(params));
    if (subs) {
      setAssetBundle(subs.assetData);
      setSubscriberPagination(subs.meta);
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (resp) => {
        if (resp.image_url == "") {
          return (
            // const [docTitle, setDocTitle] = useState("Document");
            <div>
              <Avatar
                icon={<FeatherIcon icon="user" size="30" />}
                style={{
                  backgroundColor: getRandomColor(),
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </div>
          );
        } else {
          return (
            <>
              <img
                height={40}
                width={40}
                style={{ borderRadius: "50%" }}
                crossOrigin="anonymous"
                alt=""
                src={`${API_SERVER_BASE_URL + resp}`}
              />
            </>
          );
        }
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "Title",
      // render: (record) => {
      //   return (
      //     <>{record ? `${upperCaseFirstLetter(record?.title)}` : "Empty"}</>
      //   );
      // },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "Description",
      // render: (record) => {
      //   return (
      //     <>
      //       {record ? `${upperCaseFirstLetter(record?.description)}` : "Empty"}
      //     </>
      //   );
      // },
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "assetFor",
      dataIndex: "assetFor",
      key: "assetFor",
      // render: (record) => {
      //   return <>{record.assetFor}</>;
      // },
    },
    {
      title: "Content Name",
      dataIndex: "content_name",
      key: "Content name",
    },

    {
      title: "Document",
      dataIndex: "content_url",
      key: "Document",
      align: "center",
      render: (resp) => {
        return (
          <>
            <Button
              style={{ marginRight: "15px" }}
              className="btn-icon"
              type="light"
              shape="circle"
              onClick={() => openDocVeiwer(resp)}
            >
              <FeatherIcon icon="eye" />
            </Button>
          </>
        );
      },
    },

    {
      title: "Actions",
      key: "Actions",
      align: "center",
      render: (resp) => {
        return (
          <>
            <div className="table-actions">
              <>
                <Button
                  style={{ marginRight: "15px" }}
                  className="btn-icon"
                  type="light"
                  shape="circle"
                  onClick={() => showEditModal(resp)}
                >
                  <FeatherIcon icon="edit" size={16} />
                </Button>

                <Button
                  className="btn-icon"
                  type="light"
                  shape="circle"
                  onClick={() => deleteAssetBundle(resp._id)}
                >
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </>
            </div>
          </>
        );
      },
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
    setModalTitle("Add Asset Bundle");
    form.resetFields();
    setSelectedId("");
  };

  const openDocVeiwer = (value) => {
    docs = [
      {
        uri: `${API_SERVER_BASE_URL + value}`,
      },
    ];
    setIframeValue(value);
    setIsDocOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPreviewMedia("");
    setPreviewMediaData("");
    setPreviewFileData("");
    setPreviewFile("");
  };

  const handledDocCancel = () => {
    setIsDocOpen(false);
  };

  const showEditModal = (value) => {
    setIsModalOpen(true);
    setModalTitle("Edit Asset Bundle");
    form.setFieldsValue({
      title: value.title,
      description: value.description,
      content_name: value.content_name,
      assetFor: value.assetFor,
      version: value.version,
      index: value.index ? value.index : 0,
    });

    if (value.image_url !== "") {
      setPreviewMediaData(value.image_url);
      setPreviewMedia(
        <>
          <img
            height={50}
            width={50}
            src={API_SERVER_BASE_URL + value.image_url}
            crossOrigin="anonymous"
          />
          <FeatherIcon icon="x" size="15" onClick={() => editHandler()} />
        </>
      );
    }

    if (value.content_url !== "") {
      setPreviewFileData(value.content_url);
      setPreviewFile(
        <>
          <img
            height={50}
            width={50}
            src={API_SERVER_BASE_URL + value.content_url}
            crossOrigin="anonymous"
          />
          <FeatherIcon icon="x" size="15" onClick={() => editHandler()} />
        </>
      );
    }

    setSelectedId(value._id);
  };

  const handleSubmit = async (value) => {
    if (!value.description) {
      value.description = "";
    }

    if (previewFileData == "" || previewMediaData == "") {
      message.error("Please add required feild");
    } else {
      var form_data = new FormData();
      delete value.content_url;
      delete value.image_url;

      form.resetFields();

      form_data.append("content_url", previewFileData);
      form_data.append("image_url", previewMediaData);

      for (var key in value) {
        form_data.append(key, value[key]);
      }

      if (setSelectedIdRef.current == "") {
        const create = await dispatch(
          assetBundleCreate(form_data, value.assetFor)
        );
        if (create) {
          getAssetBundleListAPI(currentPage, limit);
          setPreviewMediaData("");
          setPreviewMedia("");
          setPreviewFileData("");
          setPreviewFile("");
          setIsModalOpen(false);
          setSelectedId("");
          form.resetFields();
        }
      } else {
        const update = await dispatch(
          assetBundleUpdate(setSelectedIdRef.current, form_data)
        );
        if (update) {
          getAssetBundleListAPI(currentPage, limit);
          setPreviewFileData("");
          setPreviewFile("");
          setPreviewMediaData("");
          setPreviewMedia("");
          setIsModalOpen(false);
          setSelectedId("");
          form.resetFields();
        }
      }
    }
  };

  const deleteAssetBundle = async (value) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this Asset Bundle?",
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
        const deleteAsset = await dispatch(assetBundleDelete(value));
        if (deleteAsset) {
          getAssetBundleListAPI(currentPage, limit);
        }
      },
    });
  };

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
    getSubscribersAPI(page);
  };

  const changeHandler = (e) => {
    setPreviewMediaData(e.target.files[0]);
    setPreviewMedia(
      <>
        <img
          height={100}
          width={100}
          src={URL.createObjectURL(e.target.files[0])}
        />
        <FeatherIcon icon="x" size="20" onClick={() => editHandler()} />
      </>
    );
  };
  const editHandler = () => {
    setPreviewMedia("");
    setPreviewMediaData("");
  };
  const editFileHandler = () => {
    setPreviewFile("");
    setPreviewFileData("");
  };

  const changeHandlerFile = (e) => {
    setPreviewFileData(e.target.files[0]);
    setPreviewFile(
      <>
        <img
          height={100}
          width={100}
          src={URL.createObjectURL(e.target.files[0])}
        />
        <FeatherIcon icon="x" size="20" onClick={() => editHandlerFile()} />
      </>
    );
  };
  return (
    <>
      <PageHeader
        ghost
        title="Asset Bundle"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button size="small" type="primary" onClick={showModal}>
              <FeatherIcon icon="plus" size={14} />
              Add New
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards headless>
              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <AutoComplete
                    // onSearch={handleSearch}
                    // dataSource={notData}
                    placeholder="Search"
                    width="100%"
                    patterns
                  />
                </Col>
                <Col lg={24}>
                  <div className="table-order table-responsive">
                    {setAssetBundle && setAssetBundle.length > 0 ? (
                      <>
                        <Table
                          columns={columns}
                          dataSource={assetBundle}
                          pagination={false}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {subscriberPagination &&
                      subscriberPagination !== undefined ? (
                      <Pagination
                        total={subscriberPagination.total_counts}
                        current={currentPage}
                        pageSize={limit}
                        onChange={handlePagination}
                        simple={false}
                        hideOnSinglePage={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </Cards>
          </Col>
        </Row>
      </Main>

      <Modal
        open={isModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        closable={true}
        title={modalTitle}
        maskClosable={false}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 10 }}
          layout="vertical"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please Enter Title" }]}
          >
            <Input size="large" placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            label="Asset for"
            name="assetFor"
            rules={[{ required: true, message: "Please Select Asset" }]}
          >
            <Select style={{ width: "100%" }} placeholder="Select Asset">
              <Select.Option key="1" value="android">
                Android
              </Select.Option>
              <Select.Option key="3" value="ios">
                IOS
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Index"
            name="index"
            rules={[{ required: true, message: "Please Enter Index" }]}
          >
            <Input
              size="large"
              type="number"
              placeholder="Enter Index Number"
            />
          </Form.Item>
          <Form.Item
            label="Version"
            name="version"
            rules={[{ required: true, message: "Please Enter Version" }]}
          >
            <Input
              size="large"
              type="number"
              placeholder="Enter Index Number"
            />
          </Form.Item>
          <Row justify="start">
            <Col style={{ color: "red", marginRight: "5px" }}>*</Col>
            <Col>
              <Form.Item
                label="Content URL"
                name="content_url"
              // rules={[{ required: true, message: "Please Enter Content URL" }]}
              // initialValue={previewFileData}
              >
                <Input
                  size="large"
                  type="file"
                  placeholder="Content"
                  accept=".doc,.docx"
                  onChange={changeHandlerFile}
                />
                {previewFile ? (
                  <p>
                    <FeatherIcon icon="file-text" size="80" />
                    <FeatherIcon
                      style={{ marginBottom: "45px" }}
                      icon="x"
                      size="20"
                      onClick={() => editFileHandler()}
                    />
                  </p>
                ) : (
                  <span style={{ color: "red" }}>Please Enter Content URL</span>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Content Name"
            name="content_name"
            rules={[{ required: true, message: "Please Enter Content Name" }]}
          >
            <Input size="large" placeholder="Enter Content Name" />
          </Form.Item>

          <Row justify="start">
            <Col style={{ color: "red", marginRight: "5px" }}>*</Col>
            <Col>
              <Form.Item
                label="Image"
                name="image_url"
              // rules={[{ required: true, message: "Please Enter Image URL" }]}
              // initialValue={previewMediaData}
              >
                <Input
                  size="large"
                  type="file"
                  placeholder="Image"
                  accept="image/*"
                  onChange={changeHandler}
                />

                {previewMedia ? (
                  previewMedia
                ) : (
                  <span style={{ color: "red" }}>Please Enter Image URL</span>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Description" name="description">
            <Input
              size="large"
              placeholder="Enter Content URL"
              defaultValue={""}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Doc Model */}
      <Modal
        open={isDocOpen}
        onCancel={handledDocCancel}
        closable={true}
        title="Document"
        footer={null}
      >
        <DocViewer
          documents={docs}
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
          }}
        // style={{ height: 500 }}
        />
        <p style={{ marginTop: "15px", marginBottom: "10px" }}>
          This browser does not support embedded documents.
        </p>
      </Modal>
    </>
  );
};

export default assetBundle;
