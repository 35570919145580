import actions from "./actions";

import { message } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { API } from "../../config/api";
import { setItem } from "../../utility/localStorageControl";
import { id } from "date-fns/locale";

const { getEvent, getUser, reset } = actions;

export const resetFunction = () => {
  return async (dispatch) => {
    console.log("welcome inside");
    dispatch(reset(true));
  };
};

export const getEventData = (searchKeyword, pageSize, limit) => {
  return async (dispatch) => {
    try {
      let queryParametere =
        searchKeyword !== null
          ? "?search=" + searchKeyword + "&page=" + pageSize + "&limit=" + limit
          : "?page=" + pageSize + "&limit=" + limit;
      const res = await DataService.get(API.events.event + queryParametere);

      if (!res.data.error) {
        dispatch(getEvent(res.data.data));
        return true;
      } else {
        message.error(res.data.message);
        return false;
      }
      // console.log(" hello event", payload);
    } catch (err) {
      message.error(err.message);
      return false;
      //  dispatch(loginErr(err));
    }
  };
};

// get Category list data for dropdown
export const getArtworkId = (id) => {
  return async (dispatch) => {
    try {
      const res = await DataService.get(API.ar.getId + id);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const getArtwork = () => {
  return async (dispatch) => {
    try {
      const res = await DataService.get(API.ar.getId);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const getUserList = (x, y, role) => {
  return async (dispatch) => {
    try {
      console.log("roleInAction : ", role);
      if (role == "") {
        const res = await DataService.get(
          API.users.user + `?page=${x}&limit=${y}`
        );
        if (!res.data.error) {
          dispatch(getUser(res.data.data));
          return true;
        } else {
          message.error(res.data.message);
          return false;
        }
      } else {
        const res = await DataService.get(
          API.users.user + `?page=${x}&limit=${y}&role=${role}`
        );
        if (!res.data.error) {
          dispatch(getUser(res.data.data));
          return true;
        } else {
          message.error(res.data.message);
          return false;
        }
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const getSubscriptionList = () => {
  return async (dispatch) => {
    try {
      const res = await DataService.get(API.subscription.get);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const createPlan = (payload) => {
  console.log(";pauload : ", payload);
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.payment.post, payload);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const updatePlan = (payload) => {
  console.log(";pauload : ", payload);
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.payment.update, payload);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const deletePlan = (id) => {
  return async (dispatch) => {
    try {
      const res = await DataService.delete(API.payment.delete + id);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const subscriptionCancel = () => {
  return async (dispatch) => {
    try {
      const res = await DataService.delete(API.subscription.cancel);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const buySubscription = (payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.payment.payment, payload);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const successPayment = (payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.payment.success, payload);
      console.log("successPayment :", res);
      if (!res.data.error) {
        console.log("successPayment :", res.data.data);
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const createSubscription = (type) => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.subscription.get, type);
      if (!res.data.error) {
        setItem("subscription_data", res.data.data.type);

        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const activareAR = (id) => {
  return async (dispatch) => {
    try {
      const res = await DataService.put(API.subscription.activate + "/" + id);

      if (!res.data.error) {
        return res.data.data;
      } else {
        return false;
      }
    } catch (err) {
      // message.error(err.message);
      return false;
    }
  };
};

export const subscriberData = (params) => {
  let queryString = "?";
  let equalString = "=";
  Object.keys(params).map((v) => {
    queryString += v + equalString + params[v] + "&";
  });
  return async (dispatch) => {
    try {
      const res = await DataService.get(
        API.subscription.subscriber,
        queryString
      );
      if (!res.data.error) {
        message.success(res.data.message);
        return res.data.data;
      } else {
        // message.error(res.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const activateAllPR = () => {
  return async (dispatch) => {
    try {
      const res = await DataService.get(API.subscription.activate);

      if (!res.data.error) {
        message.success(res.data.message);
        return res.data.data;
      } else {
        // message.error(res.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const updateArtwork = (id, payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.put(API.ar.getId + id, payload);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const deactivate = () => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.ar.deacvtivate);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const changeStatus = (payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.ar.change, payload);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const changeStatusWebAr = (payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.changeWebAr.chnageWeb, payload);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const UpdateStatusWebAr = (payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.put(API.changeWebAr.updateWeb, payload);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};


export const DeleteAllWebAR = () => {
  return async (dispatch) => {
    try {
      const res = await DataService.delete(API.changeWebAr.deleteWeb);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const activate = () => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(API.ar.activate);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const updateBlock = (payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.put(API.users.block, payload);
      if (!res.data.error) {
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

// create event
export const createAR = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.ar.create, payload);
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

// update event
export const updateEvent = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.events.event + "/" + id,
        payload
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

// delete event
export const deleteEvent = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(API.events.event + "/" + id);
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      message.error(err.message);
      return false;
    }
  };
};

export const assetBundleList = (params) => {
  let queryString = "?";
  let equalString = "=";
  Object.keys(params).map((v) => {
    queryString += v + equalString + params[v] + "&";
  });
  return async (dispatch) => {
    try {
      const res = await DataService.get(API.bundle.list + queryString);

      console.log(
        " getasset actionCreator.js:464 ~ return ~ res:",
        res.data.data.assetList
      );
      if (!res.data.error) {
        // message.success(res.data.message);
        return res.data.data.assetList;
      } else {
        message.error(res.data.error);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const bundleList = (params) => {
  let queryString = "?";
  let equalString = "=";
  Object.keys(params).map((v) => {
    queryString += v + equalString + params[v] + "&";
  });
  return async (dispatch) => {
    try {
      const res = await DataService.get(API.bundle.list + queryString);

      console.log(
        " getasset actionCreator.js:464 ~ return ~ res:",
        res.data.data.assetList
      );
      if (!res.data.error) {
        // message.success(res.data.message);
        return res.data.data.assetList;
      } else {
        message.error(res.data.error);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const assetBundleCreate = (payload, type) => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(
        API.bundle.create + "?type=" + `${type}`,
        payload
      );
      console.log("🚀 ~ file: actionCreator.js:485 ~ return ~ res:", res);
      if (!res.data.error) {
        // message.success(res.data.message);
        return true;
      } else {
        message.error(res.data.error);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const bundleCreate = (payload, type) => {
  return async (dispatch) => {
    try {
      const res = await DataService.post(
        API.bundle.create + "?type=" + `${type}`,
        payload
      );
      console.log("🚀 ~ file: actionCreator.js:485 ~ return ~ res:", res);
      if (!res.data.error) {
        // message.success(res.data.message);
        return true;
      } else {
        message.error(res.data.error);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const assetBundleUpdate = (id, payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.put(API.bundle.update + "/" + id, payload);
      console.log("response error : ", payload);
      if (!res.data.error) {
        // message.success(res.data.message);
        return res.data.data;
      } else {
        return false;
      }
    } catch (err) {
      console.log("🚀 ~ file: actionCreator.js:515 ~ return ~ err:", err);
      return false;
    }
  };
};
export const bundleUpdate = (id, payload) => {
  return async (dispatch) => {
    try {
      const res = await DataService.put(API.bundle.update + "/" + id, payload);
      console.log("response error : ", payload);
      if (!res.data.error) {
        // message.success(res.data.message);
        return res.data.data;
      } else {
        return false;
      }
    } catch (err) {
      console.log("🚀 ~ file: actionCreator.js:515 ~ return ~ err:", err);
      return false;
    }
  };
};

export const assetBundleDelete = (id) => {
  return async (dispatch) => {
    try {
      const res = await DataService.delete(API.bundle.delete + "/" + id);
      if (!res.data.error) {
        // message.success(res.data.message);
        return res.data.data;
      } else {
        // message.error(res.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const bundleDelete = (id) => {
  return async (dispatch) => {
    try {
      const res = await DataService.delete(API.bundle.delete + "/" + id);
      if (!res.data.error) {
        // message.success(res.data.message);
        return res.data.data;
      } else {
        // message.error(res.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// export {
//   getEventData,
//   createAR,
//   updateEvent,
//   deleteEvent,
//   getArtworkId,
//   updateArtwork,
//   getArtwork,
//   getUserList,
//   getSubscriptionList,
//   updateBlock,
//   createSubscription,
//   activareAR,
//   activateAllPR,
// };
