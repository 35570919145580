import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  Divider,
  Modal,
  Form,
  message,
  Spin,
  Empty,
  Popconfirm,
  Select,
  Pagination,
  Avatar,
  Radio,
  Tooltip,
  Skeleton,
  Switch,
  Checkbox,
  Progress,
  Space,
} from "antd";
import { UserCard } from "../styled";
import { Link } from "react-router-dom";
import Heading from "../../components/heading/heading";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import { PageHeader } from "../../components/page-headers/page-headers";
import { useDispatch, useSelector } from "react-redux";
import { Main } from "../styled";
import {
  artworkCreate,
  artWorkDelete,
  artworkGet,
} from "../../redux/arList/actionCreator";
import { map } from "leaflet";
import { API_SERVER_BASE_URL } from "../../config/dataService/dataService";
import { DeleteFilled } from "@ant-design/icons";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import {
  shortWord,
  upperCaseFirstLetter,
  countrycode,
} from "../../utility/commonFunction";
import FeatherIcon from "feather-icons-react";
import Search from "antd/lib/transfer/search";
import {
  DeleteAllWebAR,
  UpdateStatusWebAr,
  activareAR,
  activate,
  activateAllPR,
  changeStatus,
  changeStatusWebAr,
  createSubscription,
  deactivate,
} from "../../redux/ar/actionCreator";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useStateRef from "react-usestateref";
import validator from "validator";
import { getItem, setItem } from "../../utility/localStorageControl";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { Fragment } from "react";
import { style } from "d3-selection";
import { EllipsisOutlined, SettingOutlined } from "@ant-design/icons";
import { color } from "d3-color";
import { async } from "q";
import { SocketContext } from "../../socekt/socketContext";
import { object } from "prop-types";
import moment from "moment";
import e from "cors";

const userDetails = getItem("userDetails");
const subscription = userDetails && userDetails.isSubscribed;

const artwork = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  let history = useHistory();
  const { path } = useRouteMatch();
  const [form] = Form.useForm();
  const [file, setFile] = useState();
  const [imgGet, setimgGet] = useState();
  const [imageError, setImageError] = useState(false);
  const { Meta } = Card;
  const [artWorkstore, setArtWorkstore] = useState([]);
  const [loder, setLoder] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [eventtotalCounts, setEventtotalCounts] = useState();
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [contentType, setContentType, setContentTypeRef] = useStateRef("");
  const [previewMedia, setPreviewMedia] = useState("");
  const [previewMediaData, setPreviewMediaData] = useState("");
  const [emailError, setEmailError] = useState("");
  const [active, setActive] = useState(true);
  const [conNum, setConNum] = useState(0);
  const [codeCon, setCodeCon] = useState("");
  const [singleChecked, setSingleChecked] = useState(false);
  const [radioValue, setRadioValue, setRadioValueRef] = useStateRef("active");
  const [sequence, setSequence, setSequenceRef] = useStateRef([]);
  const socket = useContext(SocketContext);
  let artWork = useSelector((state) => state.artWork.artWorkList);
  const [artworkObject, setArtworkObject] = useState({});
  const [scanCount, setScanCount] = useState({});
  const [showBtns, setShowBtns] = useState(false);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [markerPagination, setmarkerPagination] = useState();
  const [artWorkIds, setartWorkIds] = useState([]);
  const [InitialWebARIds, setInitialWebARIds] = useState([]);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isMindGenerated, setIsMindGenerated] = useState();
  const [UpdatedWebIDs, setUpdatedWebIDs] = useState([]);
  const [selectedArtworkIds, setSelectedArtworkIds] = useState([]);
  const [isList, setIsList] = useState();
  const [OldIDs, setOldIDs] = useState([]);

  const { Search } = Input;
  const limit = 10;

  useEffect(() => {
    artworkGetList(currentPage, limit, "");

    if (socket) {
      socket.on("artwork_update", (data) => {
        setArtworkObject(data);
      });
      socket.on("scan_count_update", (data) => {
        setScanCount(data);
      });
      socket.on("vuforia_reject_response", (data) => {
        setArtworkObject(data);
      });
    }
  }, [socket]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    artworkGetList(currentPage, limit, search);
  }, [search]);

  useEffect(() => {
    const storedRemainingTime = localStorage.getItem("remainingTime");
    const storedTotalTime = localStorage.getItem("totalTime");

    if (storedRemainingTime && storedTotalTime) {
      setRemainingTime(parseInt(storedRemainingTime, 10));
      setTotalTimeInSeconds(parseInt(storedTotalTime, 10));
    } else {
      const initialTotalTime = 30;
      setTotalTimeInSeconds(initialTotalTime);
      setRemainingTime(initialTotalTime);
      localStorage.setItem("totalTime", initialTotalTime.toString());
      localStorage.setItem("remainingTime", initialTotalTime.toString());
    }

    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => {
        const newTime = prevTime - 1;
        if (newTime < 0) {
          const initialTotalTime = 30;
          artworkGetList(currentPage, limit, search);
          localStorage.setItem("remainingTime", initialTotalTime.toString());
          return initialTotalTime;
        }

        localStorage.setItem("remainingTime", newTime.toString());
        return newTime;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentPage, limit, search]);

  useEffect(() => {
    const initialArtWorkIds = artWorkstore
      .filter((val) => val?.mind_status == "active")
      .map((val) => val?._id);
    setartWorkIds(initialArtWorkIds);
    setInitialWebARIds(initialArtWorkIds);
  }, [artWorkstore]);

  const artworkGetList = async (
    pageNumber = page,
    pageLimit = limit,
    searchValue = ""
  ) => {
    let query = {};
    if (searchValue === "") {
      query = {
        page: pageNumber,
        limit: pageLimit,
      };
    } else {
      query = {
        page: pageNumber,
        limit: limit,
        search: searchValue,
      };
    }
    const List = await dispatch(artworkGet(query));
    if (List) {
      setmarkerPagination(List.meta);
      const currentDate = moment().toISOString();

      setSequence([]);
      setEventtotalCounts(List.meta.totalCounts);
      setArtWorkstore(List.data);
      setIsMindGenerated(List?.meta?.isMindGenerated);
      setIsList(List?.meta);
      setSelectedArtworkIds(List?.meta?.artworksIds);

      List.data.map((l) => {
        if (l.vuforia_status == "approved") {
          setSequence((array) => [...array, l.status]);
          setShowBtns(true);
        }
        if (l.vuforia_status == "waiting for vuforia approval") {
          const diffInSeconds = moment(currentDate).diff(
            l.createdAt,
            "seconds"
          );
          if (diffInSeconds <= 60) {
            setCounter(60 - diffInSeconds);
          }
        }
      });
    }
    if (setSequenceRef.current.every((item) => item == "inactive")) {
      setRadioValue("inactive");
    } else {
      setRadioValue("active");
    }
    setLoder(false);
  };

  useEffect(() => {
    if (
      artWorkstore &&
      artWorkstore.length > 0 &&
      Object.keys(artworkObject).length > 0
    ) {
      let newArray = [...artWorkstore];
      let key;
      newArray.map((f, k) => {
        if (f._id == artworkObject._id) {
          key = k;
        }
      });
      newArray[key] = artworkObject;
      setArtWorkstore(newArray);
      setShowBtns(true);
    }
    if (
      artWorkstore &&
      artWorkstore.length > 0 &&
      Object.keys(scanCount).length > 0
    ) {
      let newArray = [...artWorkstore];
      let key;
      newArray.map((f, k) => {
        if (f._id == scanCount._id) {
          key = k;
        }
      });
      newArray[key].scan_count = scanCount.scan_count;
      setArtWorkstore(newArray);
    }
  }, [artworkObject, scanCount]);

  const DeleteWork = async (id) => {
    const deleted = await dispatch(artWorkDelete(id));
    if (deleted) {
      artworkGetList();
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
    setFile("");
    form.resetFields();
  };
  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
    artworkGetList(page, limit);
  };

  const handleCancel = () => {
    setContentType("");
    setIsModalOpen(false);
    form.resetFields();
  };
  const onFinish = async (value) => {
    if (!imgGet) {
      message.error("Image is required");
    } else {
      setLoder(true);
      var formData = new FormData();
      if (setContentTypeRef.current == "other") {
        delete value.contentType;
        formData.append("image", imgGet);
        formData.append("title", value.title);
        formData.append("description", value.description);
        formData.append("width", 7);
        formData.append("contentType", "other");
        for (const pair of formData.entries()) {
        }
        let create = await dispatch(artworkCreate(formData, value));
        if (create) {
          artworkGetList();
          setContentType("");
        }
        setCounter(60);
        setIsModalOpen(false);
        setLoder(false);
      } else if (setContentTypeRef.current == "visitor_card") {
        delete value.contentType;
        formData.append("image", imgGet);
        formData.append("title", value.title);
        formData.append("description", value.description);
        formData.append("width", 7);
        formData.append("contentType", "visitor_card");
        let create = await dispatch(artworkCreate(formData, value));
        if (create) {
          artworkGetList();
          setContentType("");
        }
        setIsModalOpen(false);
        setCounter(60);
        setLoder(false);
      }
    }
  };

  const uploadImg = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setimgGet(e.target.files[0]);
    const FileSize = e.target.files[0].size / 1024 / 1024 < 2;
    // const reader = new FileReader();
    // reader.onload = function(e) {
    //   const img = new Image();

    //   img.onload = function() {
    //     setWidth(img.naturalWidth);
    //     setHeight(img.naturalHeight);
    //   };
    //   img.src = e.target.result;
    // };
    // reader.readAsDataURL(e.target.files[0]);

    // reader.readAsDataURL(file);

    if (!FileSize) {
      message.error("Image must smaller than 2MB!");
      setImageError(true);
    } else {
      setImageError(false);
    }
  };
  const deleteImageForm = (e) => {
    setFile();
    setimgGet();
  };
  const activateAR = async (id) => {
    setLoder(true);
    const activate = await dispatch(activareAR(id));
    if (activate) {
      setTimeout(() => {
        artworkGetList();
        setLoder(false);
      }, 4000);
    } else {
      artworkGetList();
      setLoder(false);
      setUpgradeModal(true);
    }
  };

  const handleOk = () => {
    history.push("/admin/subscription");
  };
  const handleCancelUpgradeModal = () => {
    setUpgradeModal(false);
  };

  const activateAll = async () => {
    setLoder(true);
    const activate = await dispatch(activateAllPR());
    // setLoder(false);
    artworkGetList();
  };

  const handleChange = (value) => {
    setContentType(value);
  };
  const editHandler = () => {
    setPreviewMedia("");
    setPreviewMediaData("");
  };
  const changeHandler = (e) => {
    setPreviewMediaData(e.target.files[0]);
    setPreviewMedia(
      <>
        <img
          height={100}
          width={100}
          src={URL.createObjectURL(e.target.files[0])}
        />
        <FeatherIcon icon="x" size="20" onClick={() => editHandler()} />
      </>
    );
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const onValidateEmail = (e) => {
    var Email = e.target.value;

    if (validator.isEmail(Email)) {
      setEmailError();
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  const handleCountry = (value) => {
    setCodeCon(value);
  };
  const changeNumber = (e) => {
    setConNum(e);
  };
  const onButtonChange = (radio) => {
    Modal.confirm({
      title: `Are you sure you want to ${radio} all AR Marker?`,
      okText: "Yes",
      cancelText: "No",
      okType: "success",
      onOk: () => {
        if (radio == "Activate") {
          setLoder(true);
          let deactive = dispatch(activate());
          if (deactive) {
            setTimeout(() => {
              artworkGetList();
            }, 3000);
          }
        } else {
          setLoder(true);
          let ac = dispatch(deactivate());
          if (ac) {
            setTimeout(() => {
              artworkGetList();
            }, 3000);
          }
        }
      },
    });
  };
  const onRadioChange = (radio) => {
    Modal.confirm({
      title: `Are you sure you want ${radio.target.value} all card`,
      okText: "Yes",
      cancelText: "No",
      okType: "success",

      onOk: () => {
        if (radio.target.value == "active") {
          setLoder(true);
          let deactive = dispatch(activate());
          if (deactive) {
            setTimeout(() => {
              setRadioValue("active");
              artworkGetList();
            }, 3000);
          }
        } else {
          setLoder(true);
          let ac = dispatch(deactivate());
          if (ac) {
            setTimeout(() => {
              setRadioValue("inactive");
              artworkGetList();
            }, 3000);
          }
        }
      },
    });
  };

  const handleSwitchChange = async (artworkValue) => {
    Modal.confirm({
      title: `Are you sure you want ${artworkValue.status == "inactive" ? "Activate" : "Deactivate"
        } This AR Marker?`,
      okText: "Yes",
      cancelText: "No",
      okType: "success",
      onCancel: async () => {
        if (artworkValue.status == "inactive") {
          setRadioValue("inactive");
        } else {
          setRadioValue("active");
        }
      },
      onOk: async () => {
        let status = {
          artworkId: artworkValue._id,
          status: artworkValue.status,
        };
        if (artworkValue.status == "active") {
          setLoder(true);
          const change = await dispatch(changeStatus(status));
          if (change) {
            setTimeout(() => {
              artworkGetList();
            }, 3000);
          }
        } else {
          setLoder(true);
          const change = await dispatch(changeStatus(status));
          if (change) {
            setTimeout(() => {
              artworkGetList();
            }, 3000);
          }
        }
      },
    });
  };

  const handleSwitchChangeWebAR = async (WebArValue, event) => {
    Modal.confirm({
      title: `Are you sure you want to Activate this AR Marker for Web experience?`,
      okText: "Yes",
      cancelText: "No",
      okType: "success",
      onCancel: () => {
        setOldIDs((prevIds) => prevIds.filter((id) => id !== WebArValue?._id));
        setSelectedArtworkIds((prevIds) =>
          prevIds.filter((id) => id !== WebArValue?._id)
        );
      },
      onOk: () => {
        setOldIDs((prevIds) => [...prevIds, WebArValue?._id]);
      },
    });
  };

  const handleWebAR = async () => {
    // localStorage.setItem("remainingTime",30)
    let combinedArtworkIds = [...selectedArtworkIds, ...OldIDs];
    let uniqueArtworkIds = [];
    combinedArtworkIds.forEach((id) => {
      if (!uniqueArtworkIds.includes(id)) {
        uniqueArtworkIds.push(id);
      }
    });

    let checkArtWorkIds = { artWorkIds: uniqueArtworkIds };
    const change = await dispatch(UpdateStatusWebAr(checkArtWorkIds));

    if (change) {
      setTimeout(() => {
        artworkGetList(currentPage, limit, search);
      }, 3000);
    }
  };

  const handleDeactivateAllAR = async () => {
    Modal.confirm({
      title: `Are you sure you want to Deactivate all Artwork for Web-AR Experience ?`,
      okText: "Yes",
      cancelText: "No",
      okType: "success",
      onOk: async () => {
        const DeactivateAllWebAR = await dispatch(DeleteAllWebAR());
        if (DeactivateAllWebAR) {
          setTimeout(() => {
            artworkGetList(currentPage, limit, search);
          }, 4000);
          window.location.reload();
        }
      },
    });
  };

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return `${padZero(seconds)}`;
  };

  const padZero = (num) => {
    return num < 10 ? `0${num}` : `${num}`;
  };

  if (!subscription) {
    return (
      <>
        <PageHeader
          title="Your AR list"
          subTitle={
            <>
              <div
                className="table-search-box"
              // style={{ backgroundColor: 'red', display: 'flex' }}
              >
                <Search
                  className=""
                  allowClear
                  placeholder="Search"
                  onSearch={(value) => setSearch(value)}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </>
          }
          buttons={[
            <div key="1" className="page-header-actions">
              <Row justify="space-between" className="">
                <Col>
                  <Row justify="space-between" gutter={[20, 20]}>
                    {(selectedArtworkIds?.length > 0 || OldIDs?.length > 0) && (
                      <Row>
                        <Col>
                          <Button
                            size="small"
                            type="warning"
                            onClick={() => {
                              handleWebAR();
                            }}
                          >
                            Web-AR
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {selectedArtworkIds?.length > 0 && (
                      <Row>
                        <Col>
                          <Button
                            size="small"
                            type="danger"
                            onClick={() => {
                              handleDeactivateAllAR();
                            }}
                          >
                            Deactivate All Web-AR
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {artWorkstore && artWorkstore.length > 0 ? (
                      <Row justify="space-around">
                        {setRadioValueRef.current == "inactive" && showBtns ? (
                          <Col>
                            <Button
                              onClick={() => onButtonChange("Activate")}
                              size="small"
                              type="primary"
                            >
                              Activate All
                            </Button>
                          </Col>
                        ) : showBtns && setRadioValueRef.current == "active" ? (
                          <Col>
                            <Button
                              onClick={() => onButtonChange("Deactivate")}
                              size="small"
                              type="danger"
                            >
                              Deactivate All
                            </Button>
                          </Col>
                        ) : (
                          <></>
                        )}

                        {/* <Radio.Group onChange={onRadioChange} value={setRadioValueRef.current}>
                          <Radio value='active'>Activate All</Radio>
                          <Radio value='inactive'>Deactivate All</Radio>
                        </Radio.Group> */}
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row>
                      <Col>
                        {/* <Button size="large" type='primary' className='button' onClick={showModal}><span>Add new</span></Button> */}
                        <Button onClick={showModal} size="small" type="primary">
                          Add new
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </div>,
          ]}
        />

        <Main>
          <Modal
            title="Upload Your AR Marker Details"
            open={isModalOpen}
            onOk={form.submit}
            onCancel={handleCancel}
            okText={"submit"}
          >
            <Form
              name="basic"
              layout="vertical"
              form={form}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Please select content type"
                name="contentType"
                rules={[{ required: true, message: "Please Select Type" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select content type"
                  defaultValue=""
                  onChange={handleChange}
                >
                  <Select.Option key="1" value="visitor_card">
                    Visiting Card
                  </Select.Option>
                  <Select.Option key="2" value="other">
                    Media
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="AR Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Ar Title",
                  },
                ]}
              >
                <Input placeholder="Enter Ar Title" />
              </Form.Item>

              <Form.Item
                label="AR Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Event Description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter Event Description" />
              </Form.Item>

              <Form.Item>
                {file && !imageError ? (
                  <>
                    <div className="viewImg">
                      <img src={file} />
                      <button type="button" onClick={() => deleteImageForm()}>
                        <DeleteFilled
                          style={{
                            fontSize: "15px",
                            color: "#000",
                          }}
                        />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="image"
                      label={
                        <>
                          AR Image
                          <span style={{ marginLeft: "3px" }}></span>{" "}
                        </>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please upload  image",
                        },
                      ]}
                    >
                      <div className="Upload_Img_file">
                        <Input
                          type="file"
                          accept="image/jpeg,image/png"
                          id="input-file"
                          onChange={uploadImg}
                        />
                      </div>
                    </Form.Item>
                    <p style={{ color: "red" }}>
                      Only 8 bit gray scale or 24 bit RGB of file type JPG or
                      PNG are allowed. .jpg or .png (max file 2mb)
                    </p>
                  </>
                )}
              </Form.Item>
            </Form>
          </Modal>
          {loder ? (
            <>
              <div className="example">
                <Spin className="spin-position" />
              </div>
            </>
          ) : (
            <>
              <Row gutter={[16, 16]} justify="start">
                {artWorkstore && artWorkstore.length > 0 ? (
                  // && artWorkstore.data[0].scan_count !=
                  artWorkstore.map((val) => {
                    return (
                      <>
                        <Col lg={8} md={8} sm={12} xs={24}>
                          {val.vuforia_status == "approved" ? (
                            <>
                              <UserCard>
                                <div className="card user-card">
                                  <Cards headless key={val?._id}>
                                    {(val?.mind_status == "pending" ||
                                      val?.mind_status == "active") && (
                                        <Tooltip
                                          placement="Bottom"
                                          className="edit-tooltip-webar"
                                        ></Tooltip>
                                      )}
                                    {val?.mind_status == "progress" && (
                                      <Tooltip
                                        placement="Bottom"
                                        className="edit-tooltip-webar-progress"
                                      >
                                        {remainingTime >= 0 && (
                                          <Space size={30}>
                                            <Progress
                                              type="circle"
                                              format={() =>
                                                formatTime(remainingTime)
                                              }
                                              percent={
                                                (remainingTime /
                                                  totalTimeInSeconds) *
                                                100
                                              }
                                              size={50}
                                              className="TimerProgress"
                                            />
                                          </Space>
                                        )}
                                      </Tooltip>
                                    )}
                                    {val?.mind_status == "rejected" && (
                                      <Tooltip
                                        placement="Bottom"
                                        className="edit-tooltip-webar-progress"
                                      >
                                        <div className="RejectedTxt">
                                          Web-AR Rejected
                                        </div>
                                      </Tooltip>
                                    )}
                                    <figure>
                                      <div className="artwork_img">
                                        {
                                          <img
                                            src={`${API_SERVER_BASE_URL}${val.image}`}
                                            alt=""
                                          />
                                        }
                                      </div>
                                    </figure>

                                    <figcaption>
                                      <div className="card__content">
                                        <Heading className="card__name" as="h6">
                                          <Link to="#">{val.title}</Link>
                                        </Heading>
                                        <p className="card__designation">
                                          {val.description}
                                        </p>
                                      </div>

                                      <div className="card__actions">
                                        {val?.mind_status != "rejected" ? (
                                          val.status == "active" ? (
                                            <Button
                                              size="default"
                                              type="white"
                                              onClick={() =>
                                                history.push(
                                                  `/admin/editeartwork/${val._id}`
                                                )
                                              }
                                              className="other_btn"
                                            >
                                              <FeatherIcon
                                                icon="edit-2"
                                                size={14}
                                              />
                                              Edit
                                            </Button>
                                          ) : (
                                            <Button
                                              size="default"
                                              type="white"
                                              onClick={() =>
                                                handleSwitchChange(val)
                                              }
                                              className="other_btn"
                                            >
                                              <FeatherIcon
                                                icon="plus"
                                                size={14}
                                              />
                                              Activate
                                            </Button>
                                          )
                                        ) : (
                                          ""
                                        )}

                                        <Popconfirm
                                          outlined
                                          type="warning"
                                          title="Are you sure you want to delete this AR?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => DeleteWork(val._id)}
                                        >
                                          <a href="#">
                                            {" "}
                                            <Button
                                              size="default"
                                              type="white"
                                              className="delete_btn"
                                            >
                                              <FeatherIcon
                                                icon="trash"
                                                size={14}
                                              />
                                              Delete
                                            </Button>
                                          </a>
                                        </Popconfirm>
                                      </div>
                                      <div className="card__info">
                                        <Row gutter={15}>
                                          <Col xs={5}>
                                            <div className="info-single">
                                              {" "}
                                              <Heading
                                                className="info-single__title"
                                                as="h2"
                                              >
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  Counts
                                                </p>
                                              </Heading>
                                              {val.scan_count}
                                            </div>
                                          </Col>
                                          <Col xs={4}>
                                            <div className="info-single">
                                              <Heading
                                                className="info-single__title"
                                                as="h2"
                                              >
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  Type
                                                </p>
                                              </Heading>
                                              {val.content_type == "other" ? (
                                                <span>Media</span>
                                              ) : (
                                                <>
                                                  <span>Card</span>
                                                </>
                                              )}
                                            </div>
                                          </Col>
                                          <Col xs={5}>
                                            <div className="info-single">
                                              <Heading
                                                className="info-single__title"
                                                as="h2"
                                              >
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  {" "}
                                                  Status
                                                </p>
                                              </Heading>
                                              {val.status}
                                            </div>
                                          </Col>
                                          <Col xs={5}>
                                            <div className="info-single">
                                              <Heading
                                                className="info-single__title"
                                                as="h2"
                                              >
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  WebAR
                                                </p>
                                              </Heading>
                                              <Checkbox
                                                onChange={(e) => {
                                                  handleSwitchChangeWebAR(
                                                    val,
                                                    e
                                                  );
                                                }}
                                                disabled={
                                                  val?.status === "active"
                                                    ? false
                                                    : true
                                                }
                                                defaultChecked={
                                                  val?.mind_status === "active"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col xs={5}>
                                            <div className="info-single">
                                              <Heading
                                                className="info-single__title"
                                                as="h2"
                                              >
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  AR Marker
                                                </p>
                                              </Heading>
                                              <Switch
                                                className="switchToggle"
                                                // defaultChecked={val.status == "active"}
                                                checked={
                                                  val.status == "active"
                                                    ? true
                                                    : false
                                                }
                                                onChange={() =>
                                                  handleSwitchChange(val)
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </figcaption>
                                  </Cards>
                                </div>
                              </UserCard>
                            </>
                          ) : val.vuforia_status == "rejected" ? (
                            <>
                              <UserCard>
                                <div className="card user-card">
                                  <Cards headless>
                                    <figure>
                                      <div className="artwork_img">
                                        <img
                                          src={`${API_SERVER_BASE_URL}${val.image}`}
                                          alt=""
                                        />
                                      </div>
                                    </figure>
                                    <figcaption>
                                      <div className="card__content">
                                        <Heading className="card__name" as="h6">
                                          <Link to="#">
                                            {val.vuforia_status
                                              .charAt(0)
                                              .toUpperCase() +
                                              val.vuforia_status.slice(1)}
                                          </Link>
                                        </Heading>
                                        <p className="card__designation">
                                          {val.reject_reason}
                                        </p>
                                      </div>

                                      <div className="card__actions">
                                        <Popconfirm
                                          outlined
                                          type="warning"
                                          title="Are you sure you want to delete this AR?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => DeleteWork(val._id)}
                                        >
                                          <a href="#">
                                            {" "}
                                            <Button size="default" type="white">
                                              <FeatherIcon
                                                icon="trash"
                                                size={14}
                                              />
                                              Delete
                                            </Button>
                                          </a>
                                        </Popconfirm>
                                      </div>
                                      <div className="card__info__blank"></div>
                                    </figcaption>
                                  </Cards>
                                </div>
                              </UserCard>
                            </>
                          ) : (
                            <>
                              <UserCard>
                                <div className="card user-card">
                                  <Cards headless>
                                    <figure>
                                      <div className="artwork_img">
                                        {setRadioValueRef.current ==
                                          "active" ? (
                                          <img
                                            src={`${API_SERVER_BASE_URL}${val.image}`}
                                            alt=""
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </figure>
                                    <figcaption>
                                      <div className="card__content">
                                        <Heading className="card__name" as="h6">
                                          <Link to="#">
                                            {val.vuforia_status}
                                          </Link>
                                        </Heading>
                                        <p className="card__designation">
                                          {val.vuforia_msg}
                                        </p>
                                      </div>

                                      <div className="card__actions">
                                        <Popconfirm
                                          outlined
                                          type="warning"
                                          title="Are you sure you want to delete this AR?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => DeleteWork(val._id)}
                                        >
                                          <a href="#">
                                            {" "}
                                            <Button size="default" type="white">
                                              <FeatherIcon
                                                icon="trash"
                                                size={14}
                                              />
                                              Delete
                                            </Button>
                                          </a>
                                        </Popconfirm>
                                      </div>
                                      <div className="card__info__blank">
                                        <div className="countdown-timer">
                                          <div className="group">
                                            <div className="value minutes">
                                              {counter}
                                            </div>
                                            <div className="unit">Sec</div>
                                          </div>
                                        </div>
                                      </div>
                                    </figcaption>
                                  </Cards>
                                </div>
                              </UserCard>
                            </>
                          )}
                        </Col>
                      </>
                    );
                  })
                ) : (
                  // : artWorkstore.data.length > 0 && artWorkstore.data[0].scan_count == 10 ? (
                  //   <>
                  //     <div className="empty__things">
                  //       <Empty
                  //         image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  //         style={{ height: "100vh" }}
                  //         description={
                  //           <span>
                  //             Your scan limit is over, Please take subscription.
                  //           </span>
                  //         }
                  //       >
                  //         <Button
                  //           onClick={() => {
                  //             history.push({
                  //               pathname: `/admin/subscription`
                  //             })

                  //           }}
                  //           style={{ color: 'white' }}
                  //           className="ant-btn ant-btn-primary ant-btn-md"
                  //         >
                  //           <FeatherIcon icon="plus" size={14} />
                  //           Get subscription
                  //         </Button>
                  //       </Empty>
                  //     </div>
                  //   </>
                  // )
                  <>
                    {search == "" ? (
                      <>
                        <div className="empty__things">
                          <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            style={{ height: "100vh" }}
                            description={
                              <span>
                                You have not created any Art work. Create it
                                from below button.
                              </span>
                            }
                          >
                            <NavLink
                              to="#"
                              onClick={showModal}
                              className="ant-btn ant-btn-primary ant-btn-md"
                            >
                              <FeatherIcon icon="plus" size={14} />
                              Add New
                            </NavLink>
                          </Empty>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="search__not__found">
                          <img
                            src={require(`../../static/img/avatar/search.png.png`)}
                            alt="404"
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </Row>
              <Row className="pagination_Layout" justify="center">
                <Col>
                  {markerPagination && markerPagination !== undefined ? (
                    <Pagination
                      total={markerPagination.totalCounts}
                      current={currentPage}
                      pageSize={limit}
                      onChange={handlePagination}
                      simple={false}
                    // hideOnSinglePage={true}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </>
          )}

          <Modal
            title="Upgrade Plan"
            open={upgradeModal}
            onOk={handleOk}
            okText={"Upgrade Plan"}
            // confirmLoading={confirmLoading}
            onCancel={handleCancelUpgradeModal}
          >
            <p>
              {
                "Your subscription is expired or your scan limit is reached, please upgrade your subscription"
              }
            </p>
          </Modal>
        </Main>
      </>
    );
  } else {
    return (
      <>
        <PageHeader
          title="Your AR list"
          subTitle={
            <>
              <div
                className="table-search-box"
              // style={{ width: "250px", height: "50px" }}
              >
                <Search
                  className=""
                  allowClear
                  placeholder="Search"
                  onSearch={(value) => setSearch(value)}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </>
          }
          buttons={[
            <div key="1" className="page-header-actions">
              <Row justify="space-between" className="">
                <Col>
                  <Row justify="space-between" gutter={[20, 20]}>
                    {artWorkstore && artWorkstore.length > 0 ? (
                      <Row justify="space-around">
                        {setRadioValueRef.current == "inactive" ? (
                          <Col>
                            <Button
                              onClick={() => onButtonChange("Activate")}
                              size="small"
                              type="primary"
                            >
                              Activate All
                            </Button>
                          </Col>
                        ) : (
                          <Col>
                            <Button
                              onClick={() => onButtonChange("Deactivate")}
                              size="small"
                              type="danger"
                            >
                              Deactivate All
                            </Button>
                          </Col>
                        )}

                        {/* <Radio.Group onChange={onRadioChange} value={setRadioValueRef.current}>
                              <Radio value='active'>Activate All</Radio>
                              <Radio value='inactive'>Deactivate All</Radio>

                            </Radio.Group> */}
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row>
                      <Col>
                        {/* <Button size="large" type='primary' className='button' onClick={showModal}><span>Add new</span></Button> */}
                        <Button onClick={showModal} size="small" type="primary">
                          Add new
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </div>,
          ]}
        />
        <Main>
          <Modal
            title="Upload Your AR Marker Details"
            open={isModalOpen}
            onOk={form.submit}
            onCancel={handleCancel}
            okText={"submit"}
          >
            <Form
              name="basic"
              layout="vertical"
              form={form}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Please select content type"
                name="contentType"
                rules={[{ required: true, message: "Please Select Gender" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select content type"
                  defaultValue=""
                  onChange={handleChange}
                >
                  <Select.Option key="1" value="visitor_card">
                    Visiting Card
                  </Select.Option>
                  <Select.Option key="2" value="other">
                    Media
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="AR Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Ar Title!",
                  },
                ]}
              >
                <Input placeholder="Enter Ar Title" />
              </Form.Item>

              <Form.Item
                label="AR Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Event Description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter Event Description" />
              </Form.Item>

              <Form.Item>
                {file && !imageError ? (
                  <>
                    <div className="viewImg">
                      <img src={file} />
                      <button type="button" onClick={() => deleteImageForm()}>
                        <DeleteFilled
                          style={{
                            fontSize: "15px",
                            color: "#000",
                          }}
                        />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="image"
                      label={
                        <>
                          AR Image
                          <span style={{ marginLeft: "3px" }}></span>{" "}
                        </>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please upload event image",
                        },
                      ]}
                    >
                      <div className="Upload_Img_file">
                        <Input
                          type="file"
                          accept="image/jpeg,image/png"
                          id="input-file"
                          onChange={uploadImg}
                        />
                      </div>
                    </Form.Item>
                    <p style={{ color: "red" }}>
                      Only 8 bit gray scale or 24 bit RGB of file type JPG or
                      PNG are allowed. .jpg or .png (max file 2mb)
                    </p>
                  </>
                )}
              </Form.Item>

              {/* {
                setContentTypeRef.current === "visitor_card" ? (
                  <>
                    <Form.Item label="Profile Image" name="image">
                      <Input
                        size="large"
                        type="file"
                        placeholder="Choose image"
                        title=""
                        accept="image/*"
                        onChange={changeHandler}
                      />
                      {previewMedia}

                    </Form.Item>

                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[{ required: true, message: "Please Enter Name " }]}
                    >
                      <Input size="large" placeholder="Enter name" />
                    </Form.Item>



                    <Form.Item
                      label="Website"
                      name="website_url"
                    // rules={[{ required: true, message: "Please Enter website" }]}
                    >
                      <Input size="large" placeholder="Enter website" />
                    </Form.Item>

                    <Form.Item label='Mobile' name='num' rules={[{ required: true, message: "Please Enter Mobile Detail" }]} >
                      <Row justify="space-between">
                        <Col>
                          <Select
                            mode="single"
                            dropdownAlign={{ vertical: "center", horizontal: "center" }}
                            placeholder="Select Country"
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleCountry}
                            style={{ width: '200px' }}>
                            {
                              countrycode.map((c) => {

                                return (
                                  <Select.Option key={c.code} value={c.code}>
                                    {`${c.country} ${c.code})`}
                                  </Select.Option>
                                )
                              })
                            }
                          </Select>
                        </Col>
                        <Col>


                          <Input
                            type="tel"
                            style={{ width: '250px' }}
                            placeholder="Enter number"
                            onKeyDown={blockInvalidChar}
                            onChange={(e) => changeNumber(e.target.value)}
                            // onKeyUp={validateNumber}
                            maxLength={10}
                          // onInput={(e) => {
                          //   e.target.value = e.target.value.replace(/[^\d]/g, "");
                          // }}
                          />

                        </Col>

                      </Row>

                    </Form.Item>



                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please Enter Valid Email!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter email"
                        onChange={(e) => onValidateEmail(e)}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Linkedin"
                      name="linkedin"

                    >
                      <Input size="large" placeholder="Enter Linkedin url" />
                    </Form.Item>

                    <Form.Item
                      label="Facebook"
                      name="facebook"

                    >
                      <Input size="large" placeholder="Enter Facebook Detail" />
                    </Form.Item>

                    <Form.Item
                      label="Twitter"
                      name="twitter"

                    >
                      <Input size="large" placeholder="Enter Twitter Detail" />
                    </Form.Item>

                    <Form.Item
                      label="Instagram"
                      name="instagram"

                    >
                      <Input size="large" placeholder="Enter Instagram Detail" />
                    </Form.Item>

                  </>
                ) : (<></>)
              } */}
            </Form>
          </Modal>
          {loder ? (
            <>
              <div className="example">
                <Spin className="spin-position" />
              </div>
            </>
          ) : (
            <>
              <Row gutter={[16, 16]} justify="start">
                {artWorkstore && artWorkstore.length > 0 ? (
                  artWorkstore.map((val) => {
                    return (
                      <>
                        <Col lg={8} md={8} sm={12} xs={24}>
                          {val.vuforia_status == "approved" ? (
                            <>
                              <UserCard>
                                <div className="card user-card">
                                  <Cards headless>
                                    <figure>
                                      <div className="artwork_img">
                                        {
                                          <img
                                            src={`${API_SERVER_BASE_URL}${val.image}`}
                                            alt=""
                                          />
                                        }
                                      </div>
                                    </figure>
                                    {/* <Tooltip
                                      placement="Bottom"
                                      className="edit-tooltip"
                                    >
                                      <Switch
                                        // defaultChecked={val.status == "active"}
                                        checked={
                                          val.status == "active" ? true : false
                                        }
                                        onChange={() => handleSwitchChange(val)}
                                      />
                                    </Tooltip> */}
                                    <figcaption>
                                      <div className="card__content">
                                        <Heading className="card__name" as="h6">
                                          <Link to="#">{val.title}</Link>
                                        </Heading>
                                        <p className="card__designation">
                                          {val.description}
                                        </p>
                                      </div>

                                      <div className="card__actions">
                                        {val.status == "active" ? (
                                          <Button
                                            size="default"
                                            type="white"
                                            onClick={() =>
                                              history.push(
                                                `/admin/editeartwork/${val._id}`
                                              )
                                            }
                                            className="other_btn"
                                          >
                                            <FeatherIcon
                                              icon="edit-2"
                                              size={14}
                                            />
                                            Edit
                                          </Button>
                                        ) : (
                                          <Button
                                            size="default"
                                            type="white"
                                            onClick={() =>
                                              handleSwitchChange(val)
                                            }
                                            className="other_btn"
                                          >
                                            <FeatherIcon
                                              icon="plus"
                                              size={14}
                                            />
                                            Activate
                                          </Button>
                                        )}

                                        <Popconfirm
                                          outlined
                                          type="warning"
                                          title="Are you sure you want to delete this AR?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => DeleteWork(val._id)}
                                        >
                                          <a href="#">
                                            {" "}
                                            <Button
                                              size="default"
                                              type="white"
                                              className="delete_btn"
                                            >
                                              <FeatherIcon
                                                icon="trash"
                                                size={14}
                                              />
                                              Delete
                                            </Button>
                                          </a>
                                        </Popconfirm>
                                      </div>
                                      <div className="card__info">
                                        <Row gutter={15}>
                                          <Col xs={8}>
                                            <div className="info-single">
                                              {" "}
                                              <Heading
                                                className="info-single__title"
                                                as="h2"
                                              >
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  Counts
                                                </p>
                                              </Heading>
                                              {val.scan_count}
                                            </div>
                                          </Col>
                                          <Col xs={8}>
                                            <div className="info-single">
                                              <Heading
                                                className="info-single__title"
                                                as="h2"
                                              >
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  Type
                                                </p>
                                              </Heading>
                                              {val.content_type == "other" ? (
                                                <span>Media</span>
                                              ) : (
                                                <>
                                                  <span>Card</span>
                                                </>
                                              )}
                                            </div>
                                          </Col>
                                          <Col xs={8}>
                                            <div className="info-single">
                                              <Heading
                                                className="info-single__title"
                                                as="h2"
                                              >
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  {" "}
                                                  Status
                                                </p>
                                              </Heading>
                                              {val.status}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </figcaption>
                                  </Cards>
                                </div>
                              </UserCard>
                            </>
                          ) : val.vuforia_status == "rejected" ? (
                            <>
                              <UserCard>
                                <div className="card user-card">
                                  <Cards headless>
                                    <figure>
                                      <div className="artwork_img">
                                        <img
                                          src={`${API_SERVER_BASE_URL}${val.image}`}
                                          alt=""
                                        />
                                      </div>
                                    </figure>
                                    <figcaption>
                                      <div className="card__content">
                                        <Heading className="card__name" as="h6">
                                          <Link to="#">
                                            {val.vuforia_status}
                                          </Link>
                                        </Heading>
                                        <p className="card__designation">
                                          {val.reject_reason}
                                        </p>
                                      </div>

                                      <div className="card__actions">
                                        <Popconfirm
                                          outlined
                                          type="warning"
                                          title="Are you sure you want to delete this AR?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => DeleteWork(val._id)}
                                        >
                                          <a href="#">
                                            {" "}
                                            <Button size="default" type="white">
                                              <FeatherIcon
                                                icon="trash"
                                                size={14}
                                              />
                                              Delete
                                            </Button>
                                          </a>
                                        </Popconfirm>
                                      </div>
                                      <div className="card__info__blank"></div>
                                    </figcaption>
                                  </Cards>
                                </div>
                              </UserCard>
                            </>
                          ) : (
                            <>
                              <UserCard>
                                <div className="card user-card">
                                  <Cards headless>
                                    <figure>
                                      <div className="artwork_img">
                                        {setRadioValueRef.current ==
                                          "active" ? (
                                          <img
                                            src={`${API_SERVER_BASE_URL}${val.image}`}
                                            alt=""
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </figure>
                                    <figcaption>
                                      <div className="card__content">
                                        <Heading className="card__name" as="h6">
                                          <Link to="#">
                                            {val.vuforia_status}
                                          </Link>
                                        </Heading>
                                        <p className="card__designation">
                                          {val.vuforia_msg}
                                        </p>
                                      </div>

                                      <div className="card__actions">
                                        <Popconfirm
                                          outlined
                                          type="warning"
                                          title="Are you sure you want to delete this AR?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => DeleteWork(val._id)}
                                        >
                                          <a href="#">
                                            {" "}
                                            <Button size="default" type="white">
                                              <FeatherIcon
                                                icon="trash"
                                                size={14}
                                              />
                                              Delete
                                            </Button>
                                          </a>
                                        </Popconfirm>
                                      </div>
                                      <div className="card__info__blank">
                                        <div className="countdown-timer">
                                          <div className="group">
                                            <div className="value minutes">
                                              {counter}
                                            </div>
                                            <div className="unit">Sec</div>
                                          </div>
                                        </div>
                                      </div>
                                    </figcaption>
                                  </Cards>
                                </div>
                              </UserCard>
                            </>
                          )}
                        </Col>
                      </>
                    );
                  })
                ) : (
                  <>
                    {search == "" ? (
                      <>
                        <div className="empty__things">
                          <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            style={{ height: "100vh" }}
                            description={
                              <span>
                                You have not created any Art work. Create it
                                from below button.
                              </span>
                            }
                          >
                            <NavLink
                              to="#"
                              onClick={showModal}
                              className="ant-btn ant-btn-primary ant-btn-md"
                            >
                              <FeatherIcon icon="plus" size={14} />
                              Add New
                            </NavLink>
                          </Empty>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="search__not__found">
                          <img
                            src={require(`../../static/img/avatar/search.png.png`)}
                            alt="404"
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </Row>

              <Row className="pagination_Layout" justify="center">
                <Col>
                  {markerPagination && markerPagination !== undefined ? (
                    <Pagination
                      total={markerPagination.totalCounts}
                      current={currentPage}
                      pageSize={limit}
                      onChange={handlePagination}
                      simple={false}
                    // hideOnSinglePage={true}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </>
          )}

          <Modal
            title="Upgrade Plan"
            open={upgradeModal}
            onOk={handleOk}
            okText={"Upgrade Plan"}
            // confirmLoading={confirmLoading}
            onCancel={handleCancelUpgradeModal}
          >
            <p>
              {
                "Your subscription is expired or your scan limit is reached, please upgrade your subscription"
              }
            </p>
          </Modal>
        </Main>
      </>
    );
  }
};

export default artwork;
