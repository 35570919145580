/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    verify: "/auth/verify-user",
    login: "/auth/login",
    signUp: "/auth/register",
    changePassword: "/auth/forgot-password",
    resetPass: "/users/reset-password",
  },
  subscription: {
    post: "/subscription",
    get: "/subscription",
    activate: "/subscription/artwork",
    cancel: "/subscription",
    subscriber: "/admin/subscription",
  },
  asset: {
    list: "asset/list",
    create: "asset/create",
    update: "asset/update",
    delete: "asset/delete",
  },
  bundle: {
    list: "bundle/list",
    create: "bundle/create",
    update: "bundle/update",
    delete: "bundle/delete",
  },
  payment: {
    post: "payment/create-plan",
    payment: "payment/checkout",
    success: "payment/success",
    delete: "/payment/",
    update: "/payment/update-plan",
  },

  users: {
    user: "/admin",
    block: "/admin/block-unblock",
  },
  // events: {
  //   event: "/events",
  // },
  ar: {
    create: "/artwork",
    getId: "/artwork/",
    deacvtivate: "/artwork/deactivate-all",
    activate: "/artwork/activate-all",
    change: "/artwork/change-status",
    // changeWebAr:"/artwork/change-webAr"
  },
  changeWebAr:{
    chnageWeb:"/mind/addImagesToMindFile",
    updateWeb:"/mind/updateImages",
    deleteWeb:"/mind/deleteAllMindImages"
  },
  artlist: {
    list: "/artwork",
    create: "/artwork",
    update: "/artwork",
    delete: "/artwork",
    getList: "/artwork",
  },

  // categories: {
  //   categories: "/categories",
  // },
  // eventHalls: {
  //   halls: "/halls",
  // },

  dashboard: {
    counts: "/admin/dashboard",
  },

  // staticCMSPageContent: {
  //   get: "/content",
  // },
};

export { API };
