import actions from "./actions";

const { artWorkList } = actions;

const initState = {
  loading: false,
  artWorkList: {},
};

const artWorkReducerer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case artWorkList:
      return {
        ...state,
        loading: true,
        artWorkList: data,
      };
    default:
      return state;
  }
};
export default artWorkReducerer;
