const actions = {
  //DEFINE RETURN DATA SET
  GET_USER: "GET_USER",

  getUser: (data) => {
    return {
      type: actions.GET_USER,
      data,
    };
  },
};

export default actions;
