import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useRouteMatch,
} from "react-router-dom";
import AuthLayout from "../container/profile/authentication/Index";
import e from "cors";
// import NotFound from "../container/pages/404";

const Login = lazy(() =>
  import("../container/profile/authentication/overview/SignIn")
);
const SignUp = lazy(() =>
  import("../container/profile/authentication/overview/Signup")
);
const ForgotPass = lazy(() =>
  import("../container/profile/authentication/overview/ForgotPassword")
);

const ResetPass = lazy(() =>
  import("../container/profile/authentication/overview/ResetPassword")
);

// const NotFound = lazy(() => import("../container/pages/404"));

const NotFound = () => {
  let pathName = window.location.pathname.split("/");
  if (pathName.length > 2 && pathName[2] == "register") {
    return <Redirect to={`/${pathName[2]}`} />;
  } else if (pathName.length > 2 && pathName[2] == "resetpassword") {
    return (
      <Route
        exact
        path={`/resetpassword/${pathName[3]}`}
        component={ResetPass}
      />
    );
  } else {
    return <Redirect to="/" />;
  }
  // return <Redirect to="/" />;
};
// const NotFound = () => {
//   return (
//     <>
//       <h1>Page Not Found...</h1>
//     </>
//   );
// };

const FrontendRoutes = () => {
  const { path } = useRouteMatch();
  // console.log("i am in auth module", path);

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {/* <Routes> */}
        <Route exact path="/resetpassword/:id" component={ResetPass} />
        <Route exact path="/forgotPassword" component={ForgotPass} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/" component={Login} />
        <Route path="*" component={NotFound} />
        {/* <Redirect to="/404" /> */}
        {/* </Routes> */}
      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
