import React, { useState, useEffect } from "react";
import {
  Table,
  PageHeader,
  Input,
  Row,
  Col,
  Popconfirm,
  Modal,
  Form,
  Image,
  Select,
  DatePicker,
  InputNumber,
  Tooltip,
  Card,
  Tag,
  Spin,
} from "antd";
import { ListStyle, PricingCard, ListGroup, Badge } from "../styled";
import Heading from "../../components/heading/heading";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import { List } from "../../components/pricing/pricing";
import FeatherIcon from "feather-icons-react";
import useStateRef from "react-usestateref";
// import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  buySubscription,
  getArtwork,
  getSubscriptionList,
  subscriptionCancel,
} from "../../redux/ar/actionCreator";
import { CardToolbox, Main } from "../styled";
import { getItem, setItem } from "../../utility/localStorageControl";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";

import { Link, useParams } from "react-router-dom";
const user = getItem("userDetails");

const Subscription = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const userDetails = getItem("userDetails");

  // console.log("userDetails ==> ", userDetails);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [arListData, setArListData] = useState([]);
  const { Search } = Input;
  const dispatch = useDispatch();

  const params = useParams();
  //USE EFFECT FUNCTION CALL
  useEffect(() => {
    getList();
  }, []);

  //EVENT MASTER TABLE LIST API CALL
  const getList = async () => {
    const list = await dispatch(getSubscriptionList());
    console.log("list=", list);
    if (list) {
      setArListData(list);
    }
  };

  const onShowSizeChange = async (current, pageSizes) => {
    setDefaultPageSize(pageSizes);
  };

  //EVENT MASTER TABLE LIST REDUX RESPONSE
  let eventHallList = useSelector((state) => state.eventHall.eventHallList);

  let eventHallListData =
    eventHallList && eventHallList.data && eventHallList.data
      ? eventHallList.data
      : "";

  const deleteEventFunction = async (id) => {
    const deleted = await dispatch(deleteEvent(id));
    if (deleted) {
      getList(params.eventId);
    }
  };

  const createAdminSubscription = async (type) => {
    let obj = { price_id: type };
    const create = await dispatch(buySubscription(obj));
    if (create) {
      window.location.replace(create.url);
    }
  };

  const cancelSubscription = async () => {
    const cancel = await dispatch(subscriptionCancel());
    if (cancel) {
      getList();
    }
  };
  //https://api.blitzar.app/admin/subscription/success?session_id=cs_test_a123XfcwMwEzPRuma1Z6nyEbJy3whuq4C0IF2g7Rjtt19lTcnUaIIyf95X

  //REANGE PICKER IN CURRENT DATE DEFORE DATE DISABLE FUNCTION DEFINE
  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  const getSymbol = (cur) => {
    let currency = cur.toUpperCase();
    return getSymbolFromCurrency(currency);
  };

  return (
    <>
      {/* DEFINE PAGE HEADER */}
      <CardToolbox>
        <PageHeader ghost title={`Subscription`} />
      </CardToolbox>

      {/* TABLE DEFINE */}
      <Main>
        <Row gutter={[26, 26]} justifyContent="space-between">
          {arListData && arListData.length > 0 ? (
            arListData.map((val) => {
              return (
                <>
                  <Col xxl={6} lg={8} sm={12} xs={24}>
                    <PricingCard style={{ marginBottom: 30 }}>
                      <Badge className="pricing-badge" type="primary">
                        {val.type}
                      </Badge>
                      <Heading className="price-amount" as="h3">
                        <sup className="currency">
                          {val.currency ? getSymbol(val.currency) : "$"}
                        </sup>
                        {val.amount}
                        <sub className="pricing-validity">Per month</sub>
                      </Heading>
                      <span className="package-user-type">For 2 Users</span>
                      <ListGroup>
                        <List text={`Features - ${val.feature}`} />
                        <List text={`Interval Count - ${val.interval_count}`} />
                        <List text={`Interval - ${val.interval}`} />
                      </ListGroup>
                      {val.isPurchased ? (
                        <>
                          <Popconfirm
                            title="Do you want to cancel this subscription?"
                            onConfirm={() => cancelSubscription(val.price_id)}
                            placement="top"
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              size="default"
                              type=""
                              style={{ background: "#f15353" }}
                              disabled
                            >
                              Cancel Subscription
                            </Button>
                          </Popconfirm>
                        </>
                      ) : (
                        <>
                          <Popconfirm
                            title="Do you want to buy this subscription?"
                            onConfirm={() =>
                              createAdminSubscription(val.price_id)
                            }
                            placement="top"
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button size="default" type="" disabled>
                              Buy Subscription
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                    </PricingCard>
                  </Col>
                </>
              );
            })
          ) : (
            <>
              <div className="loader__container">
                <Spin />
              </div>
            </>
          )}
        </Row>
      </Main>
    </>
  );
};

export default Subscription;

{
  /* <Col md={6}>
  <Card
    className="subscription_Ui"
    style={{ height: "250px" }}
  >
    <h2>{val.type}</h2>
    <h3>{val.currency ? getSymbol(val.currency) : ''}  {val.amount}</h3>
    <p>- {val.feature}</p>
    <h4>
      {val.isPurchased ? (
        <>
          <Tag color="magenta">Subscribed</Tag>{" "}
          <Popconfirm
            title="Do you want to cancel this subscription?"
            onConfirm={() =>
              cancelSubscription(val.price_id)
            }
            placement="top"
            okText="Yes"
            cancelText="No"
          >
            <a>cancel subscription</a>
          </Popconfirm>
        </>
      ) : (
        <>
          <Tag color="blue">Not Subscribed</Tag>{" "}
          <Popconfirm
            title="Do you want to buy this subscription?"
            onConfirm={() =>
              createAdminSubscription(val.price_id)
            }
            placement="top"
            okText="Yes"
            cancelText="No"
          >
            <a>Buy</a>
          </Popconfirm>
        </>
      )}
    </h4>
  </Card>
</Col> */
}
