import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, Divider, Button } from "antd";
import { getItem, setItem } from "../../utility/localStorageControl";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { successPayment } from "../../redux/ar/actionCreator";
import { useDispatch } from "react-redux";

const userDetails = getItem("userDetails");
if (userDetails && userDetails !== null) {
  userDetails.isSubscribed = true;
}

const { Meta } = Card;
const Success = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  useMemo(() => {
    setItem("userDetails", userDetails);
    let url = window.location.href.split("=").pop();
    if (url != window.location.href) {
      let payload = { session_Id: url };
      let suc = dispatch(successPayment(payload));
    }
  }, []);

  // https://172.232.74.63:6001/admin/subscription/success?session_id=cs_test_a1AQNCgfl9Yd1cxAZJakNtFZMxZBv24nVdlgzJxtNGdB37i7yk8wIpZeXn
  const handleSuccess = async () => {
    history.push({
      pathname: `/admin/subscription`,
    });
  };
  return (
    <>
      <div className="wrapper">
        <div class="card">
          <div>
            <i class="checkmark">✓</i>
          </div>
          <h1 style={{ color: "#88B04B" }}>Success !</h1>
          <p>
            Your payment was successfull <br /> You can now continue start
            making AR Marker !
          </p>

          <Button
            size="default"
            type="primary"
            to="/admin"
            style={{ marginTop: "25px" }}
            onClick={handleSuccess}
          >
            Return
          </Button>
        </div>
      </div>
    </>
  );
};

export default Success;
