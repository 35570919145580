import React, { useState } from "react";
import { Row, Col, Space, Table, Tag, Pagination } from "antd";
import { Button } from "../../components/buttons/buttons";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import FeatherIcon from "feather-icons-react";
import { Main } from "../styled";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { subscriberData } from "../../redux/ar/actionCreator";
import { upperCaseFirstLetter } from "../../utility/commonFunction";

const limit = 10;
const subscriber = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSubscriber, setDataSubscriber] = useState([]);
  const [subscriberPagination, setSubscriberPagination] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getAssetBundleList(currentPage, limit);
  }, []);
  const getAssetBundleList = async () => {
    let params = {
      page: currentPage,
      limit: limit,
    };

    const Asset = await dispatch(assetBundleList(params));
    if (Asset) {
      console.log(Asset.data);
      setDataSubscriber(Asset.data);
      setSubscriberPagination(Asset.meta);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user_id",
      key: "name",
      render: (record) => {
        return (
          <>{`${upperCaseFirstLetter(record.firstName)} ${record.lastName}`}</>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "plan_id",
      key: "Amount",
      render: (record) => {
        return <>{record ? record.amount : 0} rs</>;
      },
    },
    {
      title: "Interval",
      dataIndex: "plan_id",
      key: "Interval",
      render: (record) => {
        return <>{record ? `${upperCaseFirstLetter(record.interval)} ` : ""}</>;
      },
    },
    {
      title: "Interval Count",
      dataIndex: "plan_id",
      key: "Interval Count",
      render: (record) => {
        return <>{record ? record.interval_count : ""}</>;
      },
    },
    {
      title: "Total Artwork",
      dataIndex: "artwork_count",
      key: "Total Artwork",
    },
    {
      title: "Scan Count",
      dataIndex: "total_scan_count",
      key: "scan count",
    },
    {
      title: "Status",
      dataIndex: "subscription_status",
      key: "status",
    },

    // {
    //     title: 'Tags',
    //     key: 'tags',
    //     dataIndex: 'tags',
    //     render: (_, { tags }) => (
    //         <>
    //             {tags.map((tag) => {
    //                 let color = tag.length > 5 ? 'geekblue' : 'green';
    //                 if (tag === 'loser') {
    //                     color = 'volcano ';
    //                 }
    //                 return (
    //                     <Tag color={color} key={tag}>
    //                         {tag.toUpperCase()}
    //                     </Tag>
    //                 );
    //             })}
    //         </>
    //     ),
    // },
  ];

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
    getSubscribersAPI(page);
  };
  return (
    <>
      <PageHeader
        ghost
        title="Subscribers"
        buttons={[
          <div key="1" className="page-header-actions">
            {/* <Button size="small" type="primary">
                        <FeatherIcon icon="plus" size={14} />
                        Add New
                    </Button> */}
          </div>,
        ]}
      />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards headless>
              <div className="table-order table-responsive">
                {setDataSubscriber && setDataSubscriber.length > 0 ? (
                  <>
                    <Table
                      columns={columns}
                      dataSource={dataSubscriber}
                      pagination={false}
                    />
                  </>
                ) : (
                  <></>
                )}
                {subscriberPagination && subscriberPagination !== undefined ? (
                  <Pagination
                    total={subscriberPagination.total_counts}
                    current={currentPage}
                    pageSize={limit}
                    onChange={handlePagination}
                    simple={false}
                    hideOnSinglePage={true}
                  />
                ) : (
                  ""
                )}
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default subscriber;
