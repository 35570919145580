import React, { useEffect, useState, Suspense } from "react";
import {
  Row,
  Col,
  Modal,
  Card,
  Form,
  Input,
  Button,
  message,
  Spin,
  Select,
  Tooltip,
} from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { useDispatch, useSelector } from "react-redux";
import { CardToolbox, Main } from "../styled";
import {
  NavLink,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Cards } from "../../components/cards/frame/cards-frame";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import useStateRef from "react-usestateref";
import { PlusOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";

import Draggable from "react-draggable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicExtended from "ckeditor5-build-classic-extended";
import {
  artworkCreate,
  artworkGetData,
  artworkupdate,
} from "../../redux/arList/actionCreator";
import { changeStatusWebAr } from "../../redux/ar/actionCreator";
import { API_SERVER_BASE_URL } from "../../config/dataService/dataService";
import { OBJModel } from "react-3d-viewer";
import { GLTFModel } from "react-3d-viewer";
import { Viewer } from "react-3d-viewer";
import validator from "validator";
import { countrycode } from "../../utility/commonFunction";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from '@ant-design/icons';
import "./mindar-image.js";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls } from "@react-three/drei";
// import GltfModel from "./Modal";
const positionObject = {
  1: { x: 0, y: -310 },
  2: { x: 200, y: -310 },
  3: { x: 380, y: -310 },
  4: { x: 0, y: -170 },
  5: { x: 200, y: -170 },
  6: { x: 380, y: -170 },
  7: { x: 0, y: -50 },
  8: { x: 200, y: -50 },
  9: { x: 380, y: -50 },
};
const editeArtwork = () => {
  //General hooks
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [formTxt] = Form.useForm();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [file, setFile] = useState();
  const [loder, setLoder] = useState(false);
  const [isDisabledBtns, setDisabledBtns] = useState({
    audio: false,
    video: false,
    text: false,
    modal: false,
    card: false,
    edit: false,
    add: false,
  });
  //Disable button hooks
  const [isDisabledBtn, setDisabledBtn] = useState(false);
  const [modelType, setModelType] = useState("");
  const [modelName, setModelName] = useState("");
  const [isDisabledBtnModal, setIsDisabledBtnModal] = useState(false);
  const [modalAvailable, setModalAvailable] = useState(false);
  const [isDisabledBtnMedia, setDisabledBtnMedia] = useState(false);
  const [isDisabledBtnAudio, setDisabledBtnAudio] = useState(false);
  const [isDisabledBtnVideo, setDisabledBtnVideo] = useState(false);
  const [isDisabledBtnGif, setDisabledBtnGif] = useState(false);
  const [isDisabledBtnCard, setDisabledBtnCard] = useState(false);
  const [moalTitle, setModalTitle, setModalTitleRef] = useStateRef("");
  // video handling hooks
  const [videosFile, setVideosFile] = useState();
  const [videosGet, setVideosGet] = useState();
  const [videoAvailable, setVideoAvailable] = useState(false);
  // gif handling hooks
  const [gidMedia, setgidMedia] = useState("");
  const [gifFile, setGifFile] = useState();
  const [gifGet, setGifGet] = useState();
  const [gifMediaData, setGifMediaData] = useState("");
  const [gifAvailable, setGifAvailable] = useState();
  // Audio handling hooks
  const [AudioFile, setAudioFile] = useState();
  const [AudioGet, setAudioGet] = useState();
  const [audioAvailable, setAudioAvailable] = useState(false);
  // Image handling hooks
  const [previewMedia, setPreviewMedia] = useState("");
  const [previewMediaData, setPreviewMediaData] = useState("");
  // Enter number and Country handling hooks
  const [conNum, setConNum] = useState(0);
  const [codeCon, setCodeCon] = useState("");

  const [payload, setPayload] = useState();
  const [contentType, setContentType] = useState("");
  const [ckEditorText, setCkEditorText] = useState("");
  const [cardName, setCardName, setCardNameRef] = useStateRef("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpenVc, setModalOpenVc] = useState(false);
  const [openWebARModal, setWebARModal] = useState(false);
  const [modelFileobj, setModelFileobj] = useState();
  const [position, setPosition] = useState({ x: 0, y: -310 });
  const [positionNumber, setPositionNumber] = useState();

  const [uploadFiles, setUploadFiles] = useState();

  const [crud, setCrud, setCrudRef] = useStateRef("");
  const [checkChange, setCheckChange, setCheckChangeRef] = useStateRef(false);
  const [visitorInfo, setVisitorInfo, setVisitorInfoRef] = useStateRef("");
  const [visitorImage, setVisitorImage, setVisitorImageRef] = useStateRef("");
  const [
    submittedFormValues,
    setSubmittedFormValues,
    submittedFormValuesRef,
  ] = useStateRef(null);
  const [
    submittedFormValuesModal,
    setSubmittedFormValuesModal,
    submittedFormValuesModalRef,
  ] = useStateRef(null);
  const [
    submittedFormValuesGif,
    setSubmittedFormValuesGif,
    submittedFormValuesGifRef,
  ] = useStateRef(null);
  const [
    submittedFormValuesAudio,
    setSubmittedFormValuesAudio,
    submittedFormValuesAudioRef,
  ] = useStateRef(null);
  const [
    submittedFormValuesVideo,
    setSubmittedFormValuesVideo,
    submittedFormValuesVideoRef,
  ] = useStateRef(null);
  const [userGetData, setUserGetData, setUserGetDataRef] = useStateRef({});

  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    artworkGetDataList();
  }, []);

  const handleCancel = () => {
    message.info(`Please fill these following details`);
  };
  const artworkGetDataList = async () => {
    const getList = await dispatch(artworkGetData(id));

    if (getList) {
      setUserGetData(getList);
      setFile(API_SERVER_BASE_URL + getList.image);
      const Audio = getList?.vuforia_metadata?.audio_data?.audio_url;
      const Video = getList?.vuforia_metadata?.video_data?.video_url;
      const Modal = getList.vuforia_metadata?.model_data?.model_url;
      const ModalName = getList?.vuforia_metadata?.model_data?.model_name;
      const Gif = getList?.vuforia_metadata?.gif_data?.gif_url;

      //If type is visiting card then open model by default to take All other required values
      if (getList.content_type == "visitor_card" && !getList?.visitor_info) {
        setModalOpenVc(true);
      }
      if (getList?.visitor_info) {
        setContentType("visitingCard");
        setCardName(getList?.visitor_info?.name);
        setCrud("edit");
        setVisitorInfo(getList.visitor_info);
        // setDisabledBtnAudio(true)
        // setDisabledBtnVideo(true)
        // setDisabledBtnCard(true)
        // setDisabledBtn(true)
        // setIsDisabledBtnModal(true)
        const img = getList.visitor_info.profile_image;
        setVisitorImage(API_SERVER_BASE_URL + img);
      } else {
        setDisabledBtnCard(true);
        setCrud("add");
        if (Audio) {
          setAudioAvailable(true);
          setSubmittedFormValuesAudio(API_SERVER_BASE_URL + Audio);
          setAudioGet(Audio);
          setDisabledBtnAudio(true);
          // setDisabledBtnVideo(true)
        }
        if (Video) {
          setVideoAvailable(true);
          setVideosGet(Video);
          setSubmittedFormValuesVideo(API_SERVER_BASE_URL + Video);
          setDisabledBtnVideo(true);
          // setDisabledBtnAudio(true)
        }
        if (Gif) {
          setGifAvailable(true);
          setDisabledBtnGif(true);
          setSubmittedFormValuesGif(API_SERVER_BASE_URL + Gif);
          setGifGet(Gif);
        }
        if (ModalName) {
          setUploadFiles(ModalName.split(".")[0]);
        }
        if (Modal) {
          setSubmittedFormValuesModal(
            API_SERVER_BASE_URL +
            getList?.vuforia_metadata?.model_data?.model_url
          );
          setModelFileobj(Modal);
          setIsDisabledBtnModal(true);
          setModalAvailable(true);
          const type = Modal.split(".").pop();
          // setModelName(
          //   Modal.split("/")
          //     .pop()
          //     .split(".")[0]
          // );
          setModelType(type);
        }

        if (getList?.vuforia_metadata?.text_data.text) {
          setCkEditorText(getList.vuforia_metadata?.text_data.text);
        } else {
          setCkEditorText();
        }

        if (
          getList.vuforia_metadata?.text_data.text != "" &&
          getList.vuforia_metadata?.text_data.text != undefined
        ) {
          setDisabledBtn(true);
          setPositionNumber(getList?.vuforia_metadata?.text_data.position_type);
        }
        setPosition(getList?.vuforia_metadata?.text_data.position);
      }
    }
  };

  const options = {
    cameraPosition: { x: 0, y: 50, z: 100 },
    backgroundColor: "#f2f2f2",
    enableZoom: true,
    enableRotate: true,
    enablePan: true,
    zoomSpeed: 1.2,
    rotateSpeed: 0.5,
    panSpeed: 1,
    enableLight: true,
    lightPosition: { x: 0, y: 0, z: 100 },
  };
  const back = () => {
    if (setCheckChangeRef.current) {
      Modal.confirm({
        title: `Do you want to save changes?`,
        okText: "Yes",
        cancelText: "No",
        okType: "success",
        onCancel: () => {
          history.push(`/admin/artwork`);
        },
        onOk: () => {
          saveFinalData();
        },
      });
    } else {
      history.push(`/admin/artwork`);
    }
  };
  const saveFinalData = async (value) => {
    setLoder(true);
    if (contentType != "visitingCard") {
      const formData = new FormData();
      formData.append("title", userGetData.title);
      formData.append("description", userGetData.description);
      formData.append("contentType", "other");

      if (modelFileobj != undefined) {
        formData.append("model", modelFileobj);
        formData.append("model_name", uploadFiles);
      } else {
        formData.append("model", "");
        formData.append("isModelDeleted", true);
      }
      // formData.append("model", {
      //   model_url: `${modelFileobj}`,
      //   model_name: uploadFiles,
      // });
      //Text
      if (value == "text-remove") {
        formData.append(
          "textData",
          JSON.stringify({
            text: "",
          })
        );
      } else {
        formData.append(
          "textData",
          JSON.stringify({
            text: ckEditorText,
            position_type: positionNumber,
            position: position,
          })
        );
      }
      // audio video
      if (gifGet != undefined) {
        formData.append("gif", gifGet);
      } else {
        formData.append("gif", "");
        formData.append("isGifDeleted", true);
      }
      if (videosGet != undefined) {
        formData.append("video", videosGet);
      } else {
        formData.append("video", "");
        formData.append("isVideoDeleted", true);
      }
      if (AudioGet != undefined) {
        formData.append("audio", AudioGet);
      } else {
        formData.append("audio", "");
        formData.append("isAudioDeleted", true);
      }

      //this 4 field will be like this only.
      formData.append("audioData", JSON.stringify({}));
      formData.append("videoData", JSON.stringify({}));
      formData.append("modelData", JSON.stringify({}));
      formData.append("pointers", JSON.stringify({}));

      let create = await dispatch(artworkupdate(id, formData));
      setLoder(false);
      history.push(`/admin/artwork`);
    }
  };
  const onFinishVc = async (value) => {
    setLoder(true);
    setCheckChange(true);
    if (previewMedia == "") {
      message.warn("Please upload image");
    } else {
      const formData = new FormData();
      delete value.image;
      delete value.profile_image;
      delete value.num;
      delete value.contentType;
      if (value.instagram == undefined) {
        delete value.instagram;
      } else {
        formData.append("instagram", value.instagram);
      }
      if (value.twitter == undefined) {
        delete value.twitter;
      } else {
        formData.append("twitter", value.twitter);
      }
      if (value.linkedin == undefined) {
        delete value.linkedin;
      } else {
        formData.append("linkedin", value.linkedin);
      }
      if (value.facebook == undefined) {
        delete value.facebook;
      } else {
        formData.append("facebook", value.facebook);
      }
      if (value.website_url == undefined) {
        delete value.website_url;
      } else {
        formData.append("website_url", value.website_url);
      }

      value.mobile = `+${codeCon} ${conNum}`;
      formData.append("profile_image", previewMediaData);
      formData.append("title", userGetData.title);
      formData.append("description", userGetData.description);
      formData.append("isProfileImageUpdate", true);
      formData.append("email", value.email);
      formData.append("mobile", value.mobile);
      formData.append("name", value.name);

      let updateArt = await dispatch(artworkupdate(id, formData));
      if (updateArt) {
        setModalOpenVc(false);
        setLoder(false);
        artworkGetDataList();
        setLoder(false);
        history.push(`/admin/artwork`);
      }
    }
  };

  const editHandler = () => {
    setPreviewMedia("");
    setPreviewMediaData("");
  };
  const changeHandler = (e) => {
    setPreviewMediaData(e.target.files[0]);
    setPreviewMedia(
      <>
        <img
          height={100}
          width={100}
          src={URL.createObjectURL(e.target.files[0])}
        />
        <FeatherIcon icon="x" size="20" onClick={() => editHandler()} />
      </>
    );
  };

  const eventHandler = (e, data) => {
  };
  const trackPos = (data) => {
    setCheckChange(true);
    setPosition({ x: data.x, y: data.y });
  };
  // upload txt Function

  const [txtIsModalOpen, setTxtIsModalOpen] = useState(false);

  const showModalTxt = (type) => {
    if (type == "audio") {
      setModalTitle("Upload your audio");
    }
    if (type == "video") {
      setModalTitle("Upload your video");
    }
    if (type == "gif") {
      setModalTitle("Upload your git");
    }
    if (type == "text") {
      setModalTitle("Upload your text");
    }
    if (type == "text-remove") {
      setModalTitle("Upload your text");
    }
    if (type == "modelDataOpen") {
      setModalTitle("Upload your 3D Model");
    }
    formTxt.setFieldsValue({
      text: ckEditorText,
      position_type: positionNumber,
    });
    setContentType(type);
    if (type == "text-remove") {
      Modal.confirm({
        title: `Are You Sure You Want To Remove Text?`,
        okText: "yes",
        okType: "success",

        onOk: async () => {
          saveFinalData("text-remove");
        },
      });
    } else {
      setTxtIsModalOpen(true);
    }
  };

  const txtHandleCancel = () => {
    if (userGetData?.vuforia_metadata?.model_data?.model_name) {
      setModelType(
        userGetData?.vuforia_metadata?.model_data?.model_name.split(".").pop()
      );
      setUploadFiles(
        userGetData?.vuforia_metadata?.model_data?.model_name.split(".")[0]
      );
    }
    setTxtIsModalOpen(false);
  };
  const submitText = async (value) => {
    setCheckChange(true);
    // setTxtIsModalOpen(false);

    if (contentType == "visitingCard") {
      if (previewMedia == "") {
        message.warn("Please upload image");
      } else {
        setLoder(false);
        setCardName(value.name);
        const formData = new FormData();
        delete value.profile_image;
        if (value.instagram == undefined) {
          delete value.instagram;
        }
        if (value.twitter == undefined) {
          delete value.twitter;
        }
        if (value.linkedin == undefined) {
          delete value.linkedin;
        }
        if (value.facebook == undefined) {
          delete value.facebook;
        }
        if (value.website_url == undefined) {
          delete value.website_url;
        }
        if (setVisitorInfoRef.current.profile_image == previewMediaData) {
        } else {
          formData.append("isProfileImageUpdate", true);
        }
        formData.append("profile_image", previewMediaData);

        formData.append("name", setCardNameRef.current);
        formData.append("title", userGetData.title);
        formData.append("description", userGetData.description);
        formData.append("contentType", "visitor_card");
        value.mobile = `${codeCon} ${conNum}`;
        delete value.num;
        delete value.name;
        for (var key in value) {
          formData.append(key, value[key]);
        }

        let updateArt = await dispatch(artworkupdate(id, formData));
        if (updateArt) {
          setCardName("");

          setDisabledBtnAudio(true);
          setDisabledBtnVideo(true);
          setDisabledBtnCard(true);
          setDisabledBtn(true);
          setIsDisabledBtnModal(true);
          setPayload(value);
          setSubmittedFormValuesVideo();
          setTxtIsModalOpen(false);
          setLoder(false);
          history.push(`/admin/artwork`);
        }
      }
    } else {
      if (contentType == "audio") {
        setModalTitle("please upload Audio");
        setCheckChange(true);
        setAudioAvailable(true);
        setSubmittedFormValuesAudio(value);
        setDisabledBtnAudio(true);
        // setDisabledBtnVideo(true)
        setDisabledBtnCard(true);
        setTxtIsModalOpen(false);
      }
      if (contentType == "video") {
        setModalTitle("please upload Video");
        setCheckChange(true);
        setVideoAvailable(true);
        setSubmittedFormValuesVideo(value);
        setDisabledBtnVideo(true);
        // setDisabledBtnAudio(true)
        setTxtIsModalOpen(false);
      }
      if (contentType == "gif") {
        setModalTitle("please upload Gif");
        setCheckChange(true);
        setGifAvailable(true);
        setSubmittedFormValuesGif(value);
        setDisabledBtnGif(true);
        setTxtIsModalOpen(false);
      }
      if (contentType == "modelDataOpen") {
        setModalTitle("please upload 3D Modal");
        setCheckChange(true);
        setModalAvailable(true);
        setIsDisabledBtnModal(true);
        setTxtIsModalOpen(false);
      }
      if (!value.text) {
        // setDisabledBtns({text:true})
        setDisabledBtnMedia(true);
      } else {
        setModalTitle("please upload Text");
        setPositionNumber(value.position_type);
        setPosition(positionObject[value.position_type]);
        setDisabledBtn(true);
        setCkEditorText(value.text);
        setTxtIsModalOpen(false);
      }
    }
  };

  const configs = {
    fullPage: true,
    allowedContent: true,
    height: "800px",
    fontFamily: {
      options: [
        "default",
        "Arial, Helvetica, sans-serif",
        "Verdana, Geneva, sans-serif",
        "Trebuchet MS, Helvetica, sans-serif",
        "Times New Roman, Times, serif",
        "Symbol, sans-serif",
        "Palatino Linotype, Book Antiqua, Palatino, serif",
        "MS Serif, New York, serif",
        "MS Sans Serif, Geneva, sans-serif",
        "Lucida Sans Unicode, Lucida Grande, sans-serif",
        "Lucida Console, Monaco, monospace",
        "Impact, Charcoal, sans-serif",
        "Georgia, serif",
        "Garamond, serif",
        "Courier New, Courier, monospace",
        "Comic Sans MS, cursive",
        "Bookman Old Style, serif",
        "Arial Black, Gadget, sans-serif",
      ],
    },
    fontSize: {
      options: [
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
      ],
    },
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "fontColor",
      "undo",
      "redo",
      "fontFamily",
      "fontSize",
    ],
  };

  const configs2 = {
    fullPage: true,
    allowedContent: true,
    height: "800px",
    fontFamily: {
      options: ["default"],
    },
    fontSize: {
      options: [
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
      ],
    },
    toolbar: ["fontColor"],
  };

  const deleteGif = () => {
    Modal.confirm({
      title: `Are You Sure You Want To delete Gif?`,
      okText: "yes",
      okType: "success",

      onOk: async () => {
        setGifFile();
        setGifGet();
        setGifAvailable(false);
        setCheckChange(true);

        // saveFinalData()
      },
    });
  };
  const deleteAudio = () => {
    Modal.confirm({
      title: `Are You Sure You Want To delete Audio?`,
      okText: "yes",
      okType: "success",

      onOk: async () => {
        setAudioFile();
        setAudioGet();
        setAudioAvailable(false);
        setCheckChange(true);

        // saveFinalData()
      },
    });
  };
  const deleteModal = () => {
    Modal.confirm({
      title: `Are You Sure You Want To delete 3D Modal?`,
      okText: "yes",
      okType: "success",

      onOk: async () => {
        setModelFileobj();
        setCheckChange(true);
        setModalAvailable(false);
        setIsDisabledBtnModal(true);
        // saveFinalData()
      },
    });
  };
  const deleteVideo = () => {
    Modal.confirm({
      title: `Are You Sure You Want To delete Video?`,
      okText: "yes",
      okType: "success",

      onOk: async () => {
        setVideosFile();
        setVideosGet();
        setVideoAvailable();
        setCheckChange(true);

        // saveFinalData()
      },
    });
  };
  const uploadVideo = (e) => {
    setVideosFile(URL.createObjectURL(e.target.files[0]));
    setVideosGet(e.target.files[0]);
  };
  const uploadGif = (e) => {
    setGifFile(URL.createObjectURL(e.target.files[0]));
    setGifGet(e.target.files[0]);
  };
  const uploadAudio = (e) => {
    setAudioFile(URL.createObjectURL(e.target.files[0]));
    setAudioGet(e.target.files[0]);
  };
  const changeModelHandler = (event) => {
    setCheckChange(true);
    setIsDisabledBtnModal(true);
    setUploadFiles(event.target.files[0].name);
    setModelType(event.target.files[0].name.split(".").pop());
    setModelFileobj(event.target.files[0]);
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const openModal = () => {
    setContentType("visitingCard");
    if (setCrudRef.current == "edit") {
      const num = setVisitorInfoRef.current.mobile.split(" ");
      const number = num[1];
      setCodeCon(num[0]);
      setConNum(num[1]);
      setCardName(setVisitorInfoRef.current.name);
      formTxt.setFieldsValue({
        name: setVisitorInfoRef.current.name,
        website_url:
          setVisitorInfoRef.current.website_url == ""
            ? ""
            : setVisitorInfoRef.current.website_url,
        mobile: number,
        email: setVisitorInfoRef.current.email,
        linkedin:
          setVisitorInfoRef.current.social_accounts.linkedin == ""
            ? ""
            : setVisitorInfoRef.current.social_accounts.linkedin,
        facebook:
          setVisitorInfoRef.current.social_accounts.facebook == ""
            ? ""
            : setVisitorInfoRef.current.social_accounts.facebook,
        twitter:
          setVisitorInfoRef.current.social_accounts.twitter == ""
            ? ""
            : setVisitorInfoRef.current.social_accounts.twitter,
        instagram:
          setVisitorInfoRef.current.social_accounts.instagram == ""
            ? ""
            : setVisitorInfoRef.current.social_accounts.instagram,
      });
      if (setVisitorImageRef.current !== "") {
        setPreviewMediaData(setVisitorInfoRef.current.profile_image);
        setPreviewMedia(
          <>
            <img
              height={50}
              width={50}
              src={setVisitorImageRef.current}
              crossOrigin="anonymous"
            />
            <FeatherIcon icon="x" size="15" onClick={() => editHandler()} />
          </>
        );
      }
    }
    setTxtIsModalOpen(true);
  };

  const handleCountry = (value) => {
    setCodeCon(value);
  };
  const changeNumber = (e) => {
    setConNum(e);
  };

  const onInputClick = (e) => {
    // e.preventDefault();
    e.stopPropagation();
  };

  const validateFacebookUrl = (rule, value) => {
    if (value && !/^https?:\/\/(www\.)?facebook.com/.test(value)) {
      return Promise.reject(
        "Please input correct Facebook Profile Page URL!Invalid Facebook URL"
      );
    }
    return Promise.resolve();
  };
  const validateInstagramUrl = (rule, value) => {
    if (value && !/^https?:\/\/(www\.)?instagram.com/.test(value)) {
      return Promise.reject("Please input correct Instagram Profile Page URL!");
    }
    return Promise.resolve();
  };
  const validateTwitterUrl = (rule, value) => {
    if (value && !/^https?:\/\/(www\.)?twitter.com/.test(value)) {
      return Promise.reject("Please input correct Twitter Profile Page URL!");
    }
    return Promise.resolve();
  };
  const validateLinkedInUrl = (rule, value) => {
    if (value && !/^https?:\/\/(www\.)?linkedin.com/.test(value)) {
      return Promise.reject("Please input correct LinkedIn Profile Page URL!");
    }
    return Promise.resolve();
  };
  const validateWebsiteUrl = (rule, value) => {
    if (
      value &&
      !/^https?:\/\/(www\.)?[A-Za-z0-9_-]+\.[A-Za-z]{2,}([/?#]|$)/.test(value)
    ) {
      return Promise.reject("Please input correct Website URL!");
    }
    return Promise.resolve();
  };
  const validateEmail = (rule, value) => {
    if (
      value &&
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
    ) {
      return Promise.reject("Invalid email");
    }
    return Promise.resolve();
  };

  // const showWebARStatus = async () => {
  //   Modal.confirm({
  //     title: "Are you sure you want to activate this marker for Web-AR",
  //     okText: "Yes",
  //     cancelText: "No",
  //     okType: "success",
  //     onOk: async () => {
  //       let status = {
  //         artworkId: userGetData?._id,
  //         status: true,
  //       };
  //         const change = await dispatch(changeStatusWebAr(status));
  //         if (change) {
  //           setTimeout(() => {
  //             userGetData();
  //           }, 3000);
  //         }
  //     },
  //   });
  // }

  const compiler = new MINDAR.IMAGE.Compiler();

  const compileFiles = async (files) => {
    const images = [];
    for (let i = 0; i < files.length; i++) {
      images.push(await loadImage(files[i]));
    }

    const dataList = await compiler.compileImageTargets(images, (progress) => {
      message.info(`Compilation Progress: ${progress.toFixed(2)}%`);
    });

    for (let i = 0; i < dataList.length; i++) {
      showData(dataList[i]);
    }

    return compiler.exportData();
  };

  const download = async (buffer) => {
    var blob = new Blob([buffer]);
    var aLink = window.document.createElement('a');
    aLink.download = 'targets.mind';
    aLink.href = window.URL.createObjectURL(blob);
    aLink.click();
    window.URL.revokeObjectURL(aLink.href);
  };

  const showData = (data) => {
  };

  const handleStart = async () => {
    if (uploadedFiles.length === 0) {
      message.error('Please upload at least one file.');
      return;
    }

    const ext = uploadedFiles[0].name.split('.').pop();
    if (ext === 'mind') {
      loadMindFile(uploadedFiles[0]);
    } else {
      const exportedBuffer = await compileFiles(uploadedFiles);
      message.success('Compilation and Visualization Completed.');
    }
  };

  const handleDownload = async () => {
    if (uploadedFiles.length === 0) {
      message.error('Please upload at least one file.');
      return;
    }

    const ext = uploadedFiles[0].name.split('.').pop();
    if (ext === 'mind') {
      message.error('Download functionality is not applicable for mind files.');
      return;
    }

    const exportedBuffer = await compileFiles(uploadedFiles);
    download(exportedBuffer);
  };

  const loadImage = async (file) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  const loadMindFile = async (file) => {
    var reader = new FileReader();
    reader.onload = () => {
      const dataList = compiler.importData(reader.result);
      for (let i = 0; i < dataList.length; i++) {
        showData(dataList[i]);
      }
      message.success('Mind file loaded successfully.');
    };
    reader.readAsArrayBuffer(file);
  };

  const props = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploadedFiles(info.fileList.map((file) => file.originFileObj));
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setUploadedFiles(Array.from(e.dataTransfer.files));
    },
  };

  return (
    <>
      <div className="WebArEditBtnDiv">
        <Row
          justify="start"
          align="middle"
          gutter={[24, 24]}
          className="int_layout"
        >
          <Col>
            <Button onClick={back}>
              <FeatherIcon icon="arrow-left" />
            </Button>
          </Col>
          <Col className="heading_layout">Edit Your AR Marker</Col>
        </Row>
        {/* <div>
          <Button
            className="WebArbtn_width"
            size="large"
            type="primary"
            onClick={() =>
              setWebARModal(true)
            }
          >
            Web-AR
          </Button>
        </div> */}
      </div>
      <Main>
        {loder ? (
          <>
            <div className="example">
              <Spin className="spin-position" />
            </div>
          </>
        ) : (
          <>
            <Row>
              <Col lg={24} xs={24}>
                <Row justify="start" gutter={[24, 24]}>
                  {contentType == "visitingCard" ? (
                    ""
                  ) : isDisabledBtnVideo ? (
                    ""
                  ) : isDisabledBtnGif ? (
                    ""
                  ) : isDisabledBtnAudio ? (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("audio")}
                      >
                        Edit Audio
                      </Button>
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("audio")}
                      >
                        Add Audio
                      </Button>
                    </Col>
                  )}
                  {contentType == "visitingCard" ? (
                    ""
                  ) : isDisabledBtnAudio ? (
                    ""
                  ) : isDisabledBtnGif ? (
                    ""
                  ) : isDisabledBtnVideo ? (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("video")}
                      >
                        Edit Video
                      </Button>
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("video")}
                      >
                        Add Video
                      </Button>
                    </Col>
                  )}

                  {contentType == "visitingCard" ? (
                    ""
                  ) : isDisabledBtnAudio ? (
                    ""
                  ) : isDisabledBtnVideo ? (
                    ""
                  ) : isDisabledBtnGif ? (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("gif")}
                      >
                        Edit GIF
                      </Button>
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("gif")}
                      >
                        Add GIF
                      </Button>
                    </Col>
                  )}

                  {contentType == "visitingCard" ? (
                    ""
                  ) : isDisabledBtn ? (
                    <Col>
                      <Button
                        size="large"
                        className="btn_width"
                        type="danger"
                        onClick={() => showModalTxt("text-remove")}
                      >
                        Delete Text
                      </Button>
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("text")}
                      >
                        Add Text
                      </Button>
                    </Col>
                  )}

                  {contentType == "visitingCard" ? (
                    ""
                  ) : isDisabledBtnModal ? (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("modelDataOpen")}
                      >
                        Edit 3D modal
                      </Button>
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("modelDataOpen")}
                      >
                        Add 3D modal
                      </Button>
                    </Col>
                  )}

                  {contentType == "visitingCard" ? (
                    ""
                  ) : isDisabledBtnCard ? (
                    ""
                  ) : (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => showModalTxt("visitingCard")}
                      >
                        Add Visiting Card
                      </Button>
                    </Col>
                  )}

                  {contentType == "visitingCard" ? (
                    ""
                  ) : setCheckChangeRef.current ? (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => saveFinalData()}
                      >
                        Submit
                      </Button>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row justify="end">
                  {setVisitorInfoRef.current ? (
                    <Col>
                      <Button
                        className="btn_width"
                        size="large"
                        type="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        Edit Card
                      </Button>{" "}
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                <Row
                  justify="space-between"
                  className="mt-30"
                  gutter={[50, 50]}
                  style={{ marginTop: "20px" }}
                >
                  <Col lg={12} md={24} xs={24}>
                    <div className="image-text-container  ">
                      <div className="viewDataImg">
                        <img src={file} />
                        {/* <div className="scan-overlay">Scan image</div> */}
                        <Draggable
                          bounds="parent"
                          defaultPosition={position}
                          position={position}
                          disabled
                        // onDrag={(e, data) => trackPos(e, data)}
                        // onMouseDown={eventHandler}
                        // onStart={eventHandler}
                        // onDrag={(e, data) => trackPos(data)}
                        // onStop={eventHandler}
                        >
                          <div>
                            {ckEditorText && (
                              <div
                                onClick={() => showModalTxt("text")}
                                className="editeBtnTxt"
                              >
                                <EditOutlined />
                              </div>
                            )}
                            <div
                              className="text-controller"
                              dangerouslySetInnerHTML={{
                                __html:
                                  ckEditorText && ckEditorText
                                    ? ckEditorText
                                    : "",
                              }}
                            />
                          </div>
                        </Draggable>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} md={12} xs={24}>
                    <div className="">
                      {!audioAvailable ? (
                        ""
                      ) : submittedFormValuesAudioRef.current ? (
                        <>
                          {submittedFormValuesAudioRef.current ? (
                            <>
                              <Row justify="start" gutter={[24, 24]}>
                                <Col>
                                  <div className="child_component">
                                    <audio
                                      controls
                                    // autoPlay
                                    // className="child_component"
                                    // style={{ width: "400px" }}
                                    >
                                      <source
                                        src={
                                          submittedFormValuesAudioRef.current
                                        }
                                        type="audio/ogg"
                                      />
                                    </audio>
                                  </div>
                                </Col>
                                <Col>
                                  <FeatherIcon
                                    icon="trash"
                                    onClick={() => deleteAudio()}
                                    style={{ marginTop: "10px" }}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {!gifAvailable ? (
                        ""
                      ) : submittedFormValuesGifRef.current ? (
                        <>
                          <Row justify="start" gutter={[24, 24]}>
                            <Col>
                              <div className="child_component">
                                <img
                                  src={submittedFormValuesGifRef.current}
                                // className="child_component"
                                // style={{ width: "400px", height: "350px" }}
                                />
                              </div>
                            </Col>
                            <Col>
                              <FeatherIcon
                                icon="trash"
                                onClick={() => deleteGif()}
                                style={{ marginTop: "10px" }}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      {!videoAvailable ? (
                        ""
                      ) : submittedFormValuesVideoRef.current ? (
                        <>
                          <Row justify="start" gutter={[24, 24]}>
                            <Col>
                              <div className="child_component">
                                <video
                                  controls="controls"
                                  // className="child_component"
                                  preload="none"
                                // width="400"
                                // height="300"
                                // autoPlay
                                >
                                  <source
                                    id="mp4"
                                    src={submittedFormValuesVideoRef.current}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            </Col>
                            <Col>
                              <FeatherIcon
                                icon="trash"
                                onClick={() => deleteVideo()}
                                style={{ marginTop: "10px" }}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                      {setVisitorInfoRef.current ? (
                        <>
                          <Col className="user-card-inside">
                            <div class="user-avatar">
                              <img
                                src={setVisitorImageRef.current}
                                alt="Profile Image"
                              />
                            </div>
                            <div class="user-info-inside">
                              <h2
                                className="text-controller-vc"
                                dangerouslySetInnerHTML={{
                                  __html: cardName && cardName ? cardName : "",
                                }}
                              />

                              <div className="social-icons">
                                <a
                                  href={`https://${setVisitorInfoRef.current.social_accounts.mail}`}
                                  target="_blank"
                                >
                                  <FeatherIcon icon="mail" size="17" />
                                </a>
                                <p className="user-email-inside">
                                  {setVisitorInfoRef.current.email}
                                </p>
                              </div>
                              <div className="social-icons">
                                <a
                                  href={`https://${setVisitorInfoRef.current.social_accounts.phone}`}
                                  target="_blank"
                                >
                                  <FeatherIcon icon="phone" size="17" />
                                </a>
                                <p className="user-email-inside">
                                  {setVisitorInfoRef.current.mobile}
                                </p>
                              </div>
                              {setVisitorInfoRef.current.website_url != null ? (
                                <>
                                  {" "}
                                  <div className="social-icons">
                                    <a
                                      href={
                                        setVisitorInfoRef.current.website_url
                                      }
                                      target="_blank"
                                    >
                                      <FeatherIcon icon="link" size="17" />
                                    </a>
                                    <p className="user-email-inside">
                                      {setVisitorInfoRef.current.website_url}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {setVisitorInfoRef.current.social_accounts
                                .twitter != null ? (
                                <>
                                  {" "}
                                  <div className="social-icons">
                                    <a
                                      href={
                                        setVisitorInfoRef.current
                                          .social_accounts.twitter
                                      }
                                      target="_blank"
                                    >
                                      <FeatherIcon icon="twitter" size="17" />
                                    </a>
                                    <p className="user-email-inside">
                                      {
                                        setVisitorInfoRef.current
                                          .social_accounts.twitter
                                      }
                                    </p>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {setVisitorInfoRef.current.social_accounts
                                .facebook != null ? (
                                <>
                                  {" "}
                                  <div className="social-icons">
                                    <a
                                      href={
                                        setVisitorInfoRef.current
                                          .social_accounts.facebook
                                      }
                                      target="_blank"
                                    >
                                      <FeatherIcon icon="facebook" size="17" />
                                    </a>
                                    <p className="user-email-inside">
                                      {
                                        setVisitorInfoRef.current
                                          .social_accounts.facebook
                                      }
                                    </p>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {setVisitorInfoRef.current.social_accounts
                                .instagram != null ? (
                                <>
                                  {" "}
                                  <div className="social-icons">
                                    <a
                                      href={
                                        setVisitorInfoRef.current
                                          .social_accounts.instagram
                                      }
                                      target="_blank"
                                    >
                                      <FeatherIcon icon="instagram" size="17" />
                                    </a>
                                    <p className="user-email-inside">
                                      {
                                        setVisitorInfoRef.current
                                          .social_accounts.instagram
                                      }
                                    </p>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {setVisitorInfoRef.current.social_accounts
                                .linkedin != null ? (
                                <>
                                  {" "}
                                  <div className="social-icons">
                                    <a
                                      href={
                                        setVisitorInfoRef.current
                                          .social_accounts.linkedin
                                      }
                                      target="_blank"
                                    >
                                      <FeatherIcon icon="linkedin" size="17" />
                                    </a>
                                    <p className="user-email-inside">
                                      {
                                        setVisitorInfoRef.current
                                          .social_accounts.linkedin
                                      }
                                    </p>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}

                      {!modalAvailable ? (
                        ""
                      ) : submittedFormValuesModalRef.current ? (
                        <>
                          <Row justify="start" gutter={[24, 24]}>
                            <Col>
                              <div
                                style={{ marginTop: "8px", fontSize: "20px" }}
                              >
                                <div className=""></div>
                                Model Name : {uploadFiles} <br />
                                Model Type : {modelType.toLocaleUpperCase()}
                                <div className="metadata_align"></div>
                              </div>
                            </Col>
                            <Col>
                              <FeatherIcon
                                icon="trash"
                                onClick={() => deleteModal()}
                                style={{ marginTop: "10px" }}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          {/* <div style={{ width: "500px", height: "250px", border: "1px solid black" }}></div> */}
                          <Row justify="start" gutter={(24, 24)}>
                            <Col>
                              <div
                                style={{ marginTop: "8px", fontSize: "20px" }}
                              >
                                <div className=""></div>
                                Model Name : {uploadFiles} <br />
                                Model Type : {modelType.toLocaleUpperCase()}
                                <div className="metadata_align"></div>
                              </div>
                            </Col>
                            <Col>
                              <FeatherIcon
                                icon="trash"
                                onClick={() => deleteModal()}
                                style={{ marginTop: "10px" }}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Main>
      {/* Modal Text Upload  */}

      <Modal
        title={setModalTitleRef.current}
        open={txtIsModalOpen}
        onCancel={txtHandleCancel}
        okText={"Submit"}
        onOk={formTxt.submit}
        width={800}
      >
        <Form
          name="nest-messages"
          layout="vertical"
          onFinish={submitText}
          form={formTxt}
        >
          {contentType == "text" && (
            <Row>
              <Col md={24} xs={24}>
                <Form.Item
                  name="text"
                  getValueFromEvent={(event, editor) => {
                    const data = editor.getData();
                    return data;
                  }}
                  rules={[{ required: true, message: "Please enter content!" }]}
                >
                  <CKEditor
                    config={configs}
                    editor={ClassicExtended}
                    data={ckEditorText}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="position_type"
                  label="Position"
                  rules={[
                    { required: true, message: "Please select position!" },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select Position"
                  >
                    <Select.Option key="1" value={1}>
                      Top Left
                    </Select.Option>
                    <Select.Option key="2" value={2}>
                      Top Center
                    </Select.Option>
                    <Select.Option key="3" value={3}>
                      Top Right
                    </Select.Option>
                    <Select.Option key="4" value={4}>
                      Middle Left
                    </Select.Option>
                    <Select.Option key="5" value={5}>
                      Middle center
                    </Select.Option>
                    <Select.Option key="6" value={6}>
                      Middle Right
                    </Select.Option>
                    <Select.Option key="7" value={7}>
                      Bottom Left
                    </Select.Option>
                    <Select.Option key="8" value={8}>
                      Bottom Center
                    </Select.Option>
                    <Select.Option key="9" value={9}>
                      Bottom Right
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          {contentType == "audio" && (
            <Row>
              <Form.Item
                name="media"
                rules={[
                  {
                    required: true,
                    message: "Please Upload Audio File ",
                  },
                ]}
              >
                <div className="Upload_Img_file">
                  <Input
                    type="file"
                    accept="audio/mp3"
                    id="input-file"
                    onChange={uploadAudio}
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      color: "#4f35ba",
                      fontWeight: "600",
                    }}
                  >
                    Please Upload Audio
                  </p>
                </div>
              </Form.Item>
            </Row>
          )}

          {contentType == "gif" && (
            <Row>
              <Form.Item
                name="gif"
                rules={[
                  {
                    required: true,
                    message: "Please Upload Gif File ",
                  },
                ]}
              >
                <div className="Upload_Img_file">
                  <Input
                    type="file"
                    accept="image/gif"
                    id="input-file"
                    onChange={uploadGif}
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      color: "#4f35ba",
                      fontWeight: "600",
                    }}
                  >
                    Please Upload Gif
                  </p>
                </div>
              </Form.Item>
            </Row>
          )}

          {contentType == "video" && (
            <Row>
              <Form.Item
                name="media"
                rules={[
                  {
                    required: true,
                    message: "Please Upload Media File Image",
                  },
                ]}
              >
                <div className="Upload_Img_file">
                  <Input
                    type="file"
                    accept="video/mp4,video/mp3,video/*"
                    id="input-file"
                    onChange={uploadVideo}
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      color: "#4f35ba",
                      fontWeight: "600",
                    }}
                  >
                    Please Upload Video
                  </p>
                </div>
              </Form.Item>
            </Row>
          )}

          {contentType == "modelDataOpen" && (
            <>
              <Row>
                <Form.Item
                  name="model"
                  rules={[
                    {
                      required: true,
                      message: "Please Upload3D Modal",
                    },
                  ]}
                >
                  <div>
                    <Input
                      type="file"
                      accept=".OBJ,.FBX,.STL,.GLTF,.zip"
                      id="input-files"
                      onChange={changeModelHandler}
                    // onClick={onInputClick}
                    />
                  </div>
                </Form.Item>
              </Row>

              <Row>
                <p style={{ color: "red" }}>
                  Ideal Size [1cm (file) to 1m (unity)]
                </p>
              </Row>
            </>
          )}
          {contentType == "visitingCard" && (
            <>
              <Form.Item label="Upload Image" name="profile_image">
                <Input
                  size="large"
                  type="file"
                  placeholder="Choose image"
                  title=""
                  accept="image/*"
                  onChange={changeHandler}
                />
                {previewMedia == "" ? (
                  <span style={{ color: "red" }}>Please Upload Image</span>
                ) : (
                  previewMedia
                )}
              </Form.Item>

              {/* <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: "Please Enter Name " }]}
                            >
                                <CKEditor config={configs2} editor={ClassicExtended} data={cardName} />
                            </Form.Item> */}
              <Form.Item
                name="name"
                label="Name"
                getValueFromEvent={(event, editor) => {
                  const data = editor.getData();
                  return data;
                }}
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <CKEditor
                  config={configs2}
                  editor={ClassicExtended}
                  data={setCardNameRef.current}
                />
              </Form.Item>

              <Form.Item
                label="Website"
                name="website_url"
                rules={[
                  {
                    required: false,
                    message: "Please input your Website URL!",
                  },
                  {
                    validator: validateWebsiteUrl,
                  },
                ]}
              >
                <Input placeholder="https://www.your-website.com" />
              </Form.Item>

              <Form.Item
                label="Mobile"
                rules={[
                  { required: true, message: "Please Enter Mobile Detail" },
                ]}
              >
                <Row justify="start">
                  <Col>
                    <Select
                      value={codeCon}
                      mode="single"
                      defaultValue={codeCon}
                      dropdownAlign={{
                        vertical: "center",
                        horizontal: "center",
                      }}
                      placeholder="Select Country"
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={handleCountry}
                      style={{ width: "200px" }}
                    >
                      {countrycode.map((c) => {
                        return (
                          <Select.Option key={c.code} value={c.code}>
                            {`${c.country} (+${c.code})`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col>
                    <Form.Item name="mobile">
                      <Input
                        type="tel"
                        style={{ width: "250px" }}
                        placeholder="Enter number"
                        defaultValue={setConNum}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => changeNumber(e.target.value)}
                        // onKeyUp={validateNumber}
                        maxLength={10}
                      // onInput={(e) => {
                      //   e.target.value = e.target.value.replace(/[^\d]/g, "");
                      // }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  {
                    validator: validateEmail,
                  },
                ]}
              >
                <Input placeholder="example@domain.com" />
              </Form.Item>

              <Form.Item
                label="Linkedin"
                name="linkedin"
                rules={[
                  {
                    required: false,
                    message: "Please input correct LinkedIn Profile Page URL!",
                  },
                  {
                    validator: validateLinkedInUrl,
                  },
                ]}
              >
                <Input placeholder="https://www.linkedin.com/in/your-profile-page" />
              </Form.Item>

              <Form.Item
                label="facebook"
                name="facebook"
                rules={[
                  {
                    required: false,
                    message: "Please input correct Facebook Profile Page URL!",
                  },
                  {
                    validator: validateFacebookUrl,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="https://www.facebook.com/your-profile-page"
                />
              </Form.Item>

              <Form.Item
                label="Twitter"
                name="twitter"
                rules={[
                  {
                    required: false,
                    message: "Please input correct Twitter Profile Page URL!",
                  },
                  {
                    validator: validateTwitterUrl,
                  },
                ]}
              >
                <Input placeholder="https://twitter.com/your-profile-page" />
              </Form.Item>

              <Form.Item
                label="Instagram"
                name="instagram"
                rules={[
                  {
                    required: false,
                    message: "Please input your Instagram Profile Page URL!",
                  },
                  {
                    validator: validateInstagramUrl,
                  },
                ]}
              >
                <Input placeholder="https://www.instagram.com/your-profile-page" />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      <Modal
        title="Upload Your Data"
        open={modalOpenVc}
        closeIcon={true}
        okText={"submit"}
        footer={[
          <Button key="submit" type="primary" onClick={form.submit}>
            Submit
          </Button>,
        ]}
      >
        <Form
          name="basic"
          layout="vertical"
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishVc}
          autoComplete="off"
        >
          <Form.Item label="Profile Image" name="image">
            <Input
              size="large"
              type="file"
              src={previewMediaData}
              placeholder="Choose image"
              title=""
              accept="image/*"
              onChange={changeHandler}
            />
            {previewMedia == "" ? (
              <span style={{ color: "red" }}>Please Upload Image</span>
            ) : (
              previewMedia
            )}
          </Form.Item>

          {/* <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please Enter Name " }]}>
                        <CKEditor config={configs2} editor={ClassicExtended} data={cardName} />
                    </Form.Item> */}
          <Form.Item
            name="name"
            label="Name"
            getValueFromEvent={(event, editor) => {
              const data = editor.getData();
              return data;
            }}
            rules={[{ required: true, message: "Please enter name!" }]}
          >
            <CKEditor
              config={configs2}
              editor={ClassicExtended}
              data={setCardNameRef.current}
            />
          </Form.Item>

          <Form.Item
            label="Website"
            name="website_url"
            rules={[
              {
                required: false,
                message: "Please input your Website URL!",
              },
              {
                validator: validateWebsiteUrl,
              },
            ]}
          >
            <Input placeholder="https://www.your-website.com" />
          </Form.Item>

          <Form.Item
            label="Mobile"
            name="num"
            rules={[{ required: true, message: "Please Enter Mobile Detail" }]}
          >
            <Row justify="space-between">
              <Col>
                <Select
                  mode="single"
                  dropdownAlign={{ vertical: "center", horizontal: "center" }}
                  placeholder="Select Country"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleCountry}
                  style={{ width: "200px" }}
                >
                  {countrycode.map((c) => {
                    return (
                      <Select.Option key={c.code} value={c.code}>
                        {`${c.country} (+${c.code})`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <Col>
                <Input
                  type="tel"
                  style={{ width: "250px" }}
                  placeholder="Enter number"
                  onKeyDown={blockInvalidChar}
                  onChange={(e) => changeNumber(e.target.value)}
                  // onKeyUp={validateNumber}
                  maxLength={10}
                // onInput={(e) => {
                //   e.target.value = e.target.value.replace(/[^\d]/g, "");
                // }}
                />
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                validator: validateEmail,
              },
            ]}
          >
            <Input placeholder="example@domain.com" />
          </Form.Item>

          <Form.Item
            label="Linkedin"
            name="linkedin"
            rules={[
              {
                required: false,
                message: "Please input correct LinkedIn Profile Page URL!",
              },
              {
                validator: validateLinkedInUrl,
              },
            ]}
          >
            <Input placeholder="https://www.linkedin.com/in/your-profile-page" />
          </Form.Item>

          <Form.Item
            label="Facebook"
            name="facebook"
            rules={[
              {
                required: false,
                message: "Please input correct Facebook Profile Page URL!",
              },
              {
                validator: validateFacebookUrl,
              },
            ]}
          >
            <Input
              size="large"
              placeholder="https://www.facebook.com/your-profile-page"
            />
          </Form.Item>

          <Form.Item
            label="Twitter"
            name="twitter"
            rules={[
              {
                required: false,
                message: "Please input correct Twitter Profile Page URL!",
              },
              {
                validator: validateTwitterUrl,
              },
            ]}
          >
            <Input placeholder="https://twitter.com/your-profile-page" />
          </Form.Item>

          <Form.Item
            label="Instagram"
            name="instagram"
            rules={[
              {
                required: false,
                message: "Please input correct Instagram Profile Page URL!",
              },
              {
                validator: validateInstagramUrl,
              },
            ]}
          >
            <Input placeholder="https://www.instagram.com/your-profile-page" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Upload Web-ar image"
        open={openWebARModal}
        closeIcon={true}
        okText={"submit"}
        footer={[
          <Button key="submit" type="primary" onClick={handleStart}>
            Start
          </Button>,
          <Button key="download" type="primary" onClick={handleDownload}>
            Download
          </Button>,
        ]}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
          </p>
        </Dragger>
      </Modal>
    </>
  );
};

export default editeArtwork;
`   `;
