import actions from "./actions";

const { GET_USER } = actions;

const initState = {
  loading: false,
  userList: {},
};

const userReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        loading: true,
        // user: data?.data?.data?.docs,
        userList: data,
      };
    default:
      return state;
  }
};
export default userReducer;
