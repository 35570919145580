import React from 'react'
import { Row, Col, Card, Divider, Button } from 'antd'
import { ErrorWrapper } from "./style";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";


const Cancel = () => {
  const { path } = useRouteMatch()

  const history = useHistory();
  const handleCancel = () => {
    history.push({
      pathname: `/admin/subscription`
    })
  }
  return (
    <>
      <div className='wrapper'>
        <div class="card">
          {/* <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;"> */}
          <div>
            <i style={{ color: 'red' }} class="">x</i>
          </div>
          <h1 style={{ color: 'red' }}>Cancelled !</h1>
          <p>Your payment was Cancel <br /> Please try again!</p>

          <Button size="default" type="primary" to="/admin" style={{ marginTop: '25px' }} onClick={handleCancel}>
            Return
          </Button>

        </div>
      </div>
    </>
  )
}

export default Cancel